import $ from 'jquery';
import '../../../js/vertical-tabs-animate';

$(function setVerticalTabsForHome() {
  function getTab(val) {
    const index = parseInt(val, 10);
    switch (index) {
      case 0: return '.form-vertical-tab__backups';
      case 1: return '.form-vertical-tab__snapshots';
      case 2: return '.form-vertical-tab__archivado';
      case 3: return '.form-vertical-tab__redimensionado';
      case 4: return '.form-vertical-tab__red-privada';
      case 5: return '.form-vertical-tab__api';
    }
  }

  function getAnimationTab(val) {
    const index = parseInt(val, 10);
    switch (index) {
      default:
      case 0: return 'backups';
      case 1: return 'snapshots';
      case 2: return 'archivado';
      case 3: return 'redimensionado';
      case 4: return 'red-privada';
      case 5: return 'api';
    }
  }

  let currentTabIndex = 0;
  var ele = document.getElementsByName('tabs');
  for (var i = 0; i < ele.length; i++) {
    if (ele[i].checked)
      currentTabIndex = ele[i].value;
  }

  $(getTab(currentTabIndex)).addClass('active');

  $('.home input[type=radio][name=tabs]').click(function (e) {

    const index = e.target.value;

    // If we click the same tab, don't do anything.
    if (index === parseInt(currentTabIndex, 10)) return;

    const $currentTab = $(getTab(currentTabIndex));
    const $nextTab = $(getTab(index));

    currentTabIndex = index;
    update($currentTab, $nextTab);

    animateIcon(getAnimationTab(index));
  });

  $('.form-vertical-tab__range').on('input', function () {
    const value = parseInt(this.value, 10);
    const steps = $('.form-vertical-tab__range').data('steps');
    const stepAmount = 1000 / steps;

    const index = Math.round(value / stepAmount);

    if (index === parseInt(currentTabIndex, 10)) return;

    const $currentTab = $(getTab(currentTabIndex));
    const $nextTab = $(getTab(index));

    currentTabIndex = index;
    update($currentTab, $nextTab);

    animateIcon(getAnimationTab(index));
  });


  let timeout;
  function update($currentTab, $nextTab) {
    const cardPlaceholderContainer = $('.form-vertical-tab__card-placeholder-container');
    cardPlaceholderContainer.removeClass("move");
    cardPlaceholderContainer.addClass("move");

    const contentCard = $('.form-vertical-tab__card-content');
    contentCard.removeClass("move");
    contentCard.addClass('move');

    clearTimeout(timeout);

    timeout = setTimeout(function () {
      cardPlaceholderContainer.removeClass("move");
      contentCard.removeClass("move");
    }, 300);

    $currentTab.removeClass('active');
    $nextTab.addClass('active');
  }

  function animateIcon(name) {
    if (!name) return;

    window.lottieObject[name].goToAndPlay(0);
  }
});





// The ghost cards behind the main card extends past the page width.
// This is fine for large screens, but causes horizontal scrolling behavior on smaller screens.
$(function setOverflowForCards() {
  $(window).resize(checkOverflow);

  function checkOverflow() {
    const docWidth = $(document).outerWidth();

    if (docWidth < 1258 && docWidth > 750) {
      $('.section4').css({ 'overflow': 'hidden', 'margin-bottom': -30, 'padding-bottom': 30 });
    }
    else {
      $('.section4').css({ 'overflow': '', 'margin-bottom': '', 'padding-bottom': '' });
    }
  }

  checkOverflow();
});
