document.addEventListener("DOMContentLoaded", function () {
  var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          let lazyImage = entry.target;
          
          if (lazyImage.dataset.src)
            lazyImage.src = lazyImage.dataset.src;

          if (lazyImage.dataset.srcset)
            lazyImage.srcset = lazyImage.dataset.srcset;

          lazyImage.classList.remove("lazy");
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    // Possibly fall back to a more compatible method here

    lazyImages.forEach(function (lazyImage) {
      if (lazyImage.dataset.src)
        lazyImage.src = lazyImage.dataset.src;

      if (lazyImage.dataset.srcset)
        lazyImage.srcset = lazyImage.dataset.srcset;

      lazyImage.classList.remove("lazy");
    });
  }
});