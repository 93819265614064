import $ from 'jquery';

$(function () {

  function getTab(val) {
    const index = parseInt(val, 10);
    switch (index) {
      case 0: return '.form-circle-tabs-hardware';
      case 1: return '.form-circle-tabs-software';
      case 2: return '.form-circle-tabs-red';
      case 3: return '.form-circle-tabs-imagenes';
      case 4: return '.form-circle-tabs-data-center';
    }
  }

  let currentTabIndex = 0;
  var ele = document.getElementsByName('project');
  for (var i = 0; i < ele.length; i++) {
    if (ele[i].checked)
      currentTabIndex = ele[i].value;
  }

  // let currentTabIndex = $('.form-circle-tabs input').val();
  $(getTab(currentTabIndex)).fadeIn();

  let inTransition = false;

  $('.form-circle-tabs input').click(function (e) {
    if (inTransition) {
      e.preventDefault();
      return;
    }
    inTransition = true;
    $(getTab(currentTabIndex)).fadeOut(300, function () {
      currentTabIndex = e.target.value;
      $(getTab(currentTabIndex)).fadeIn(300, function () {
        inTransition = false;
      });
    });
  });

});
