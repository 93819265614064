document.addEventListener("DOMContentLoaded", function () {
  const formInputs = document.querySelectorAll("input.form-input__element");
  console.log('formInputs', formInputs);
  [...formInputs].map(formInput => {
    function loadRecaptcha() {
      const recaptcha = document.getElementById('recaptcha');
      recaptcha.src = recaptcha.dataset.src;
    }

    formInput.addEventListener('click', loadRecaptcha);
    formInput.addEventListener('change', loadRecaptcha);
  })
});
