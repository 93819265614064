import $ from 'jquery';
import testimonials from './testimonial-data';

document.addEventListener("DOMContentLoaded", function () {

  const slideContent = document.querySelector('.testimonial-carousel__slide-content');

  const testimonialIds = shuffle([...Object.keys(testimonials)]);

  // Make a copy that we will POP to get the index from.
  // let indexes = [...testimonialIds];
  let indexes = [0];

  function getNextTestimonialIndex() {
    if (indexes.length === 0) {
      indexes = [...testimonialIds];
    }

    return indexes.pop();
  }

  let lazyImageObserver;
  let isIntersecting = false;
  if ("IntersectionObserver" in window) {
    lazyImageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        isIntersecting = entry.isIntersecting;
        console.log(isIntersecting)
        if (entry.isIntersecting) {
          let lazyImage = entry.target;

          if (lazyImage.dataset.src)
            lazyImage.src = lazyImage.dataset.src;
          if (lazyImage.dataset.srcset)
            lazyImage.srcset = lazyImage.dataset.srcset;
        }
      });
    });
    const image = slideContent.querySelector('.testimonial-carousel__slide-image');
    lazyImageObserver.observe(image);
  }


  // Set first slide
  function updateSlide(testimonial) {
    const image = slideContent.querySelector('.testimonial-carousel__slide-image');
    const quote = slideContent.querySelector('.testimonial-carousel__slide-quote');
    const name = slideContent.querySelector('.testimonial-carousel__slide-name');
    const position = slideContent.querySelector('.testimonial-carousel__slide-position');

    slideContent.href = testimonial.href;

    // If we are lazy loading, we need to display the placeholder.
    image.src = (lazyImageObserver && !isIntersecting) ?
      '/img/placeholder.png' :
      testimonial.img.desktop.src;

    if (isIntersecting) {
      // data-srcset="image@2x.png 751w, image.png 750w"
      image.srcset = `${testimonial.img.desktop.src} 751w, ${testimonial.img.mobile.src} 750w`;
    }
    else {
      // data-srcset="image@2x.png 751w, image.png 750w"
      image.srcset = ""
      image.dataset.srcset = `${testimonial.img.desktop.src} 751w, ${testimonial.img.mobile.src} 750w`;
    }

    if (window.innerWidth > 750) {
      image.width = testimonial.img.desktop.width;
      image.height = testimonial.img.desktop.height;
    }
    else {
      image.width = testimonial.img.mobile.width;
      image.height = testimonial.img.mobile.height;
    }

    quote.textContent = testimonial.quote['es'];
    name.textContent = testimonial.name;
    position.textContent = testimonial.position['es'];

  }

  updateSlide(testimonials[getNextTestimonialIndex()]);

  $(slideContent).fadeIn(function () {
    setTimeout(presentNextSlide, 3000);
  });

  // Automatically move the carousel
  function presentNextSlide() {
    $(slideContent).fadeOut(function () {
      updateSlide(testimonials[getNextTestimonialIndex()]);

      $(slideContent).fadeIn(function () {
        setTimeout(presentNextSlide, 3000)
      });
    });
  }

});

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

