import $ from 'jquery';

$(function clickTabAnimateCircle() {
  $('input[type=radio][name=tabs]').click(function (e) {

    const index = parseInt(this.value, 10);
    const steps = $('.form-vertical-tab__range').data('steps');
    const stepAmount = 1000 / steps;
    const newValue = stepAmount * index;
    const currentValue = $('.form-vertical-tab__range').val();

    $({ val: currentValue }).animate({ val: newValue }, {
      duration: 200,
      step: function (val) {
        $('.form-vertical-tab__range').val(val);
      }
    });

  });

});

$(function () {

  $('.form-vertical-tab__range').on('input', function () {
    const value = parseInt(this.value, 10);
    const steps = $('.form-vertical-tab__range').data('steps');
    const stepAmount = 1000 / steps;

    const index = Math.round(value / stepAmount);
    const currentIndex = parseInt($(`input[type=radio][name=tabs][checked=checked]`).val(), 10);
    if (index === currentIndex) return;
    $(`input[type=radio][name=tabs]`).removeAttr('checked');
    $(`input[type=radio][name=tabs][value=${index}]`).prop('checked', true);

  });

});

$(function adjustOnMouseUp() {
  $('.form-vertical-tab__range').mouseup(adjustCircle);
  $('.form-vertical-tab__range').on('touchend', adjustCircle);

  function adjustCircle() {
    const value = parseInt(this.value, 10);
    const steps = $('.form-vertical-tab__range').data('steps');
    const stepAmount = 1000 / steps;

    const index = Math.round(value / stepAmount);

    const newValue = stepAmount * index;
    const currentValue = $('.form-vertical-tab__range').val();

    $({ val: currentValue }).animate({ val: newValue }, {
      duration: 200,
      step: function (val) {
        $('.form-vertical-tab__range').val(val);
      }
    });

  }
});


$(function freezeBackgroundOnDrag() {
  $('.form-vertical-tab__range').on('touchstart', function () {
    $('body').css('overflow', 'hidden');
  });

  $('.form-vertical-tab__range').on('touchend', function () {
    $('body').css('overflow', '');
  });

});