import $ from 'jquery';
import { scrollTo } from './scroll-to-contact';

// This was the original a[href^="#"]
$(function () {

  $(document).on('click', 'a', function (event) {
    if (this.dataset.ignoreScroll === "true") return;

    const locationWithoutHash = location.href.replace(location.hash, "").replace("#", "");
    const anchorLocationWithoutHash = this.href.replace(this.hash, "").replace("#", "");

    if (locationWithoutHash === anchorLocationWithoutHash) {

      // Check if there's a matching element
      if ($(this.hash).length > 0) {
        scrollTo($(this.hash).offset().top);
      }
      else {
        // If there's no matching hash to an ID, scroll to the top.
        scrollTo(0);
      }

    }
  });

});