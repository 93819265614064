import lottie from 'lottie-web';

window.lottieObject = {};

$(function() {
  var lazyImages = [].slice.call(document.querySelectorAll("div.lottie-lazy"));

  if ("IntersectionObserver" in window) {
    let lazyImageObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;

          const lottieAnimation = lottie.loadAnimation({
            container: lazyImage, // the dom element that will contain the animation
            renderer: 'svg',
            autoplay: false,
            loop: false,
            path: lazyImage.dataset.animationPath // the path to the animation json
          });


          const name = lazyImage.dataset.animationName;
          if (name) {
            window.lottieObject[name] = lottieAnimation;
          }

          if (lazyImage.dataset.animationAutoPlay === 'true') {
            const desktopDelay = (lazyImage.dataset.animationDelayDesktop) ? parseInt(lazyImage.dataset.animationDelayDesktop) : 0;
            const mobileDelay = (lazyImage.dataset.animationDelayMobile) ? parseInt(lazyImage.dataset.animationDelayMobile) : 0;

            const isMobile = window.innerWidth <= 750;
            const delay = (isMobile) ? mobileDelay : desktopDelay;

            setTimeout(() => {
              lottieAnimation.goToAndPlay(0, true);
            }, delay);
          }

          if (lazyImage.dataset.animationFreezeFrame) {
            const freezeFrame = parseInt(lazyImage.dataset.animationFreezeFrame);
            lottieAnimation.goToAndStop(freezeFrame, true);
          }

          if (lazyImage.dataset.animationOnHover) {

            let element = lazyImage;

            lazyImage.dataset.animationOnHover
              .split(' ')
              .forEach(relative => {
                if (relative === 'parent') {
                  element = element.parentElement;
                }
              });


            element.addEventListener('mouseenter', function() {
              console.log('mouseenter');
              lottieAnimation.goToAndPlay(0, true);
            });
          }

          lazyImageObserver.unobserve(lazyImage);
        }
      });
    }, {
      rootMargin: '0px 0px 0px 0px',
      threshold: 0.25,
      root: null,
    });

    lazyImages.forEach(function (lazyImage) {
      lazyImageObserver.observe(lazyImage);
    });
  } else {
    // Possibly fall back to a more compatible method here

    lazyImages.forEach(function (lazyImage) {
      lottie.loadAnimation({
        container: lazyImage, // the dom element that will contain the animation
        renderer: 'svg',
        autoplay: true,
        path: lazyImage.dataset.animationPath // the path to the animation json
      });
    });
  }
});
