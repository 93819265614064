import $ from 'jquery';

$(function mobileMenuFunctionality() {

  $('.hamburger').click(function () {
    $('.hamburger').toggleClass('active');
    $('.mobile-menu').toggleClass('active');

    if (!$('.hamburger').hasClass('active')) {
      // Remove after 300ms because we don't want the collapsing animation
      // to be visible when closing the full menu.
      setTimeout(function () {
        $('.mobile-menu__item').removeClass('active');
      }, 300);
    }
  });

  // Select all caracteristicas menus (including the floating header)
  // and open both on click.
  const caracteristicas = $('.js-mobile-menu__header-caracteristicas');
  const nosotros = $('.js-mobile-menu__header-nosotros');
  const soporte = $('.js-mobile-menu__header-soporte');

  caracteristicas.click(function () {
    // Close other tabs
    nosotros.parent().removeClass('active');
    soporte.parent().removeClass('active');

    caracteristicas.parent().toggleClass('active');
  });

  nosotros.click(function () {
    // Close other tabs
    caracteristicas.parent().removeClass('active');
    soporte.parent().removeClass('active');

    nosotros.parent().toggleClass('active');
  });

  soporte.click(function () {
    // Close other tabs
    caracteristicas.parent().removeClass('active');
    nosotros.parent().removeClass('active');

    soporte.parent().toggleClass('active');
  });


  $('.px-global-nav-dropdown').click(function () {
    $('.hamburger').removeClass('active');
    $('.mobile-menu').removeClass('active');

    // Remove after 300ms because we don't want the collapsing animation
    // to be visible when closing the full menu.
    setTimeout(function () {
      $('.mobile-menu__item').removeClass('active');
    }, 300);
  });

});
