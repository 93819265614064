import $ from 'jquery';

$(function() {
  $('.faq__question-container').click(function() {
    const hadClass = $(this).parent().hasClass('active');
    $('.faq__item').removeClass('active');

    if(!hadClass) {
      $(this).parent().addClass('active');
    }

  })
});