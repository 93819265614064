import $ from 'jquery';

$(function () {

  $('.dropdown-menu').click(function () {
    const $elem = $(this).closest('.nav-row__nav-item');
    $elem.removeClass('active');
    $elem.addClass('deactive');

    setTimeout(function () {
      $elem.removeClass('deactive');
    }, 600);
  });

});


$(function animateDropdowns() {
  // We have to do it this way because there's an existing bug
  // in chrome that can temporarily display :hover attributes.
  //
  // see https://stackoverflow.com/questions/14389566/stop-css-transition-from-firing-on-page-load
  // tried workaround of empty script block, but still seeing the problem.
  $('.nav-row__nav-item').mouseover(function () {
    const images = [...this.querySelectorAll('img.dropdown-lazy')];

    images.forEach(function (image) {
      if (image.dataset.src)
        image.src = image.dataset.src;

      if (image.dataset.srcset)
        image.srcset = image.dataset.srcset;

      image.classList.remove("dropdown-lazy");
    });


    $(this).addClass('active');
  });

  $('.nav-row__nav-item').mouseout(function () {
    $(this).removeClass('active');
  });
});