import $ from 'jquery';
import text from '../data/text';

// Added browser form validation for the register and contact sections.
// Converted Recaptcha to use the HTML binding approach so we can validate the
// forms with the Browser default validators and validate prior to executing
// Recaptcha. We were running slow on the previous implementation because
// Recaptcha was "resetting" after each form validation, which was causing
// some race conditions.

var recaptchaWidgets = {};
window.recaptchaOnLoadCallback = function() {
  var recaptchaElements = document.getElementsByClassName("g-recaptcha");

  for (var i = 0; i < recaptchaElements.length; i++) {
    var element = recaptchaElements[i];

    var formId = element.dataset.formId;
    var sitekey = element.dataset.sitekey;
    var size = element.dataset.size;
    var callback = element.dataset.callback;

    var widgetId = grecaptcha.render(element, {
        sitekey,
        size,
        callback,
    });

    recaptchaWidgets[formId] = widgetId;
  }
}

var formId;

window.formOnSubmit = function homeOnSubmit(event, id) {
  event.preventDefault();

  formId = id;

  var widgetId = recaptchaWidgets[formId];

  grecaptcha.execute(widgetId);
  return false;
}

window.recaptchaOnSubmit = function recaptchaOnSubmit(token) {
  switch (formId) {
    case "#home__form":
    case "#home__register-section":
    case "#ventajas__register-section":
    case "#funcionalidades__register-section":
    case "#infraestructura__register-section": {
      const $root = $(formId);
      // Remove `onsubmit` because it will just trigger the onsubmit call
      // not the actual form submission.
      $root.removeAttr('onsubmit');
      $root.submit();
      break;
    }

    case "#nosotros-contacto-id__form":
    case "#contacto-id__form":
    case "#cloud-pros__form":
      const $root = $(formId);
      const tag = $root.find('[name=department]').val();
      const locale = $root.find('input[name=locale]').val();
      const subject = $root.find('input[name=subject]').val();

      const name = $root.find('input[name=name]').val();
      const company = $root.find('input[name=company]').val();
      const email = $root.find('input[name=email]').val();
      const phone = $root.find('input[name=phone-number]').val();
      const message = $root.find('textarea[name=message]').val();

      const body = `Nombre: ${name}\n` +
        `company: ${company}\n` +
        `email: ${email}\n` +
        `phone_number: ${phone}\n` +
        `Mensaje: ${message}`;

      const data = {
        request: {
          requester: {
            name,
            email,
            locale,
          },
          subject,
          tags: [tag],
          comment: {
            body
          }
        }
      };

      // Google Tag Manager Conversion tracking
      const isCloudPro = tag === "api_cloudpro_request";
      if (isCloudPro) {
        // Only Cloud Pro
        dataLayer.push({
          'event': 'virtualPageView',
          'virtualPageURL': '/actionforms/pros-action.php',
          'virtualPageTitle': 'Envio Formulario de Cloud Pros'
        });
      }
      else {
        // All but Cloud Pro
        dataLayer.push({
          'event': 'virtualPageView',
          'virtualPageURL': '/actionforms/contacto-action.php',
          'virtualPageTitle': 'Envio Formulario Contacto Web'
        });
      }

      // Send the Create Request to Zendesk.
      $.ajax({
        url: 'https://clouding.zendesk.com/api/v2/requests.json',
        type: "POST",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: JSON.stringify(data),
        success: () => $('.contact-form__form').addClass('success'),
        error: err => console.log(err),
      });
      break;
    default:
      break;
  }
}

window.invalidEmail = function invalidEmail(textbox) {
  const lang = $('html').attr('lang');
  if (textbox.value == '') {
    textbox.setCustomValidity(text("Este campo es obligatorio", lang));
  }
  else if (textbox.validity.typeMismatch) {
    textbox.setCustomValidity(text("Este campo es obligatorio", lang));
  }
  else {
    textbox.setCustomValidity('');
  }
  return true;
}

window.invalidPassword = function invalidPassword(textbox) {
  const lang = $('html').attr('lang');
  if (textbox.value == '') {
    textbox.setCustomValidity(text("Debe tener al menos 6 caracteres", lang));
  }
  else if (textbox.validity.tooShort) {
    textbox.setCustomValidity(text("Debe tener al menos 6 caracteres", lang));
  }
  else {
    textbox.setCustomValidity('');
  }
  return true;
}
