import $ from 'jquery';

$(document).ready(function () {
  const settings = {
    fill: '#1565C0FF',
    background: '#1565C092'
  }

  const sliders = $('input[type=range].range');

  sliders.on('input', (event) => {
    applyBackground(event.target);
  });

  sliders.trigger('input');

  function applyBackground(slider) {
    const percentage = 100 * (slider.value - slider.min) / (slider.max - slider.min);
    const bg = `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${settings.background} ${percentage + 0.1}%)`;
    slider.style.background = bg;
  }
});