export default {
  // Language Dropdown Menu
  "spanish": {
    "es": "Español",
    "en": "Spanish",
    "ca": "Espanyol",
  },

  "english": {
    "es": "Inglés",
    "en": "English",
    "ca": "Anglès",
  },

  "catalan": {
    "es": "Catalán",
    "en": "Catalan",
    "ca": "Català",
  },

  // CARACTERÍSTICAS
  // API
  "api-tab-title": {
    "es": "API",
    "en": "API",
    "ca": "API",
  },

  "api-tab-description": {
    "es": "Conecta tu software a Clouding a través de nuestra API, automatiza tu trabajo y, ¡olvídate de gestiones innecesarias! Con nuestra API podrás realizar todas las acciones de tu panel de cliente: crear, archivar y redimensionar servidores, activar backups y snapshots, añadir reglas de firewall y, ¡mucho más!",
    "en": "Connect your software to Clouding through our API, automate your work and forget about unnecessary management! With our API you will be able to perform all the actions of your client panel: create, archive and resize servers, activate backups and snapshots, add firewall rules and much more!",
    "ca": "Connecta el teu software a Clouding a través de la nostra API, automatitza el teu treball i oblida't de gestions innecessàries! Amb la nostra API podràs realitzar totes les accions del teu panell de client: crear, arxivar i redimensionar servidors, activar backups i snapshots, afegir regles de firewall i, molt més!",
  },

  "api-tab-link": {
    "es": "Consulta la documentación oficial de la API de Clouding",
    "en": "View the official Clouding API documentation",
    "ca": "Consulta la documentació oficial de l’API de Clouding",
  },

  // FUNCIONALIDADES
  "funcionalidades-section1-title": {
    "es": "Crea en 30 segundos",
    "en": "Set it up in 30 seconds",
    "ca": "Crea en 30 segons",
  },

  "funcionalidades-section1-description": {
    "es": "Tener un Servidor Cloud VPS disponible en una plataforma de alto rendimiento está a tu alcance. Escoge la RAM que necesites, los vCores que buscas y los GB de SSD NMVe que más te convenzan, inicia y ¡listo! En menos de 30 segundos estarás dentro y haciendo tus primeras pruebas.",
    "en": "Having a Cloud VPS Server available on a high performance platform is at your fingertips. Choose the RAM you need, the vCores you’re looking for and the SSD NMVe GB you like the most, start and that’s it! In less than 30 seconds you’ll be inside and doing your first tests.",
    "ca": "Tenir un Servidor Cloud VPS disponible en una plataforma d'alt rendiment està al teu abast. Tria la RAM que necessitis, els vCores que cerques i els GB de SSD NMVe que més et convencin, inicia i llest! En menys de 30 segons estaràs dins i fent les teves primeres proves.",
  },

  "funcionalidades-section2-title": {
    "es": "Borra y crea cuando quieras",
    "en": "Delete and set up whenever you want",
    "ca": "Esborra i crea quan vulguis",
  },

  "funcionalidades-section2-description": {
    "es": "Nuestra plataforma te permite la libertad que estás buscando. Crear, ampliar, modificar o borrar servidores en la Nube es fácil e intuitivo en Clouding. Haz todo lo que necesites desde tu panel de control.",
    "en": "Our platform allows you the freedom you’re looking for. Setting up, expanding, modifying or deleting servers in the Cloud is easy and intuitive at Clouding and you can do everything you need from your control panel.",
    "ca": "La nostra plataforma et permet la llibertat que estàs buscant. Crear, ampliar, modificar o esborrar servidors al Núvol és fàcil i intuïtiu a Clouding. Fes tot el que necessitis des del teu panell de control.",
  },

  "funcionalidades-section3-title": {
    "es": "Archivado",
    "en": "Archived",
    "ca": "Arxivat",
  },

  "funcionalidades-section3-description": {
    "es": "En Clouding, la gestión de tu servidor la decides tú. Archívalo temporalmente, cuando no lo uses o para tus pruebas: no pagarás ni RAM ni CPU. Ahorrarás mientras mantienes tus datos intactos y podrás desarchivarlo cuando quieras.",
    "en": "At Clouding, you decide the management of your server. Archive it temporarily, when you’re not using it or for tests: you won’t pay any RAM or CPU either, and so save money while keeping your data intact and can unarchive it whenever you want.",
    "ca": "A Clouding, la gestió del teu servidor la decideixes tu. Arxiva'l temporalment, quan no el facis servir o per a les teves proves: no pagaràs ni RAM ni CPU. Estalviaràs mentre mantens les teves dades intactes i podràs desarxivarlo quan vulguis.",
  },

  "funcionalidades-section4-title": {
    "es": "Redimensionado",
    "en": "Resized",
    "ca": "Redimensionat",
  },

  "funcionalidades-section4-description": {
    "es": "¡No estás atado a nada! Modifica el tamaño de tu Servidor Cloud cuando lo necesites. Siempre podrás aumentar tus recursos para dar más capacidad a tus proyectos. ¿Quieres volver atrás? ¡No hay problema! Reduce la configuración de tus servidores en segundos.",
    "en": "You’re not tied into anything at all! Change the size of your cloud server whenever you need. You can always increase your resources to give your projects more space. Want to go back to a previous setup? No problem! Reduce the size of your servers in seconds.",
    "ca": "No estàs lligat a res! Modifica la mida del teu Servidor Cloud quan ho necessitis. Sempre podràs augmentar els teus recursos per donar més capacitat als teus projectes. Vols tornar enrere? No hi ha problema! Redueix la configuració dels teus servidors en segons.",
  },

  "funcionalidades-section5-title": {
    "es": "Snapshots y Clonado",
    "en": "Snapshots and Cloning",
    "ca": "Snapshots i Clonat",
  },

  "funcionalidades-section5-description": {
    "es": "En Clouding siempre puedes volver atrás en instantes. Haz una captura instantánea de tu servidor con nuestros Snapshots, por si algo sale mal o estás realizando una actualización. O clona un servidor para hacer tus pruebas, ¡cuando quieras!",
    "en": "At Clouding you can always go back in just a few moments. Take an instant capture of your server with our Snapshots, in case something goes wrong or you’re doing an update, or even clone a server to do your tests, whenever you want!",
    "ca": "A Clouding sempre pots tornar enrere en instants. Fes una captura instantània del teu servidor amb els nostres Snapshots, per si alguna cosa surt malament o estàs realitzant una actualització. O clona un servidor per fer les teves proves, ¡quan vulguis!",
  },

  "funcionalidades-section6-title": {
    "es": "Disaster Recovery",
    "en": "Disaster Recovery",
    "ca": "Disaster Recovery",
  },

  "funcionalidades-section6-description": {
    "es": "¿Se han borrado tus datos y no sabes cómo volver atrás?, ¿has cometido un error y has perdido parte de tu trabajo? En Clouding puedes revertir tu servidor hasta 48 horas atrás en caso de cualquier fallo. ¡Fuera preocupaciones! Sigue con tu proyecto como si no hubiera pasado nada.",
    "en": "Has your data been erased and you don’t know how to go back? Made a mistake and lost part of your work? At Clouding you can reverse your server up until 48 hours beforehand in the event of something going wrong. No worries! Continue with your project as if nothing had happened.",
    "ca": "S'han esborrat les teves dades i no saps com tornar enrere?, has comès un error i has perdut part de la teva feina? A Clouding pots revertir el teu servidor fins a 48 hores enrere en cas de qualsevol error. Fora preocupacions! Segueix amb el teu projecte com si no hagués passat res.",
  },

  "funcionalidades-section7-title": {
    "es": "Backups",
    "en": "Backups",
    "ca": "Backups",
  },

  "funcionalidades-section7-description": {
    "es": "En un par de clics tendrás configurado tu sistema de Backups: diarios, semanales, quincenales, etc. Solo tienes que definir cuántos quieres y cuándo los quieres, ¡así de fácil! Nosotros los almacenaremos en un sistema totalmente aislado, para que los restaures lo más rápido posible. ¡Todo ello gracias a la tecnología de Clouding!",
    "en": "In just a few clicks you’ll have your backup system set up: daily, weekly, biweekly, etc. You just have to select how many you want and when you want them, it’s that easy! We’ll store them in a totally isolated system, so you can restore them as quickly as possible. All thanks to the Clouding technology!",
    "ca": "En un parell de clics tindràs configurat el teu sistema de Backups: diaris, setmanals, quinzenals, etc. Només has de definir quants vols i quan els vols, així de fàcil! Nosaltres els emmagatzemarem en un sistema totalment aïllat, perquè els restauris el més ràpid possible. Tot això gràcies a la tecnologia de Clouding!",
  },

  "funcionalidades-section8-title": {
    "es": "Red Privada",
    "en": "Private Network",
    "ca": "Xarxa Privada",
  },

  "funcionalidades-section8-description": {
    "es": "Tus Servidores Cloud podrán intercambiar datos entre ellos de forma segura gracias a tu VLAN privada y dedicada. Nadie más tendrá acceso a tus comunicaciones internas, haciendo más fáciles y seguras las configuraciones de plataformas con múltiples servidores.",
    "en": "Your Cloud servers can securely exchange data with each other thanks to their private and dedicated VLAN. No one else will have access to your internal communications, making platform configurations with multiple servers easier and safer.",
    "ca": "Els teus Servidors Cloud podran intercanviar dades entre ells de forma segura gràcies a la teva VLAN privada i dedicada. Ningú més tindrà accés a les teves comunicacions internes, fent més fàcils i segures les configuracions de plataformes amb múltiples servidors.",
  },

  "funcionalidades-section9-title": {
    "es": "Firewall",
    "en": "Firewall",
    "ca": "Firewall",
  },

  "funcionalidades-section9-description": {
    "es": "En nuestra plataforma podrás gestionar tu propio Firewall desde el panel de control, ¡una medida más de seguridad para mantener a raya a cualquier intruso! Controla y bloquea el acceso a tu Cloud VPS y líbrate de los ataques externos o de accesos no autorizados.",
    "en": "On our platform you can manage your own Firewall from the control panel, one more security measure to keep any intruders at bay! Control and block access to your Cloud VPS and get rid of external attacks or unauthorized access.",
    "ca": "A la nostra plataforma podràs gestionar el teu propi Firewall des del panell de control, una mesura més de seguretat per mantenir a ratlla a qualsevol intrús! Controla i bloqueja l'accés al teu Cloud VPS i lliura't dels atacs externs o d’accessos no autoritzats.",
  },

  "funcionalidades-section10-title": {
    "es": "Consola de Emergencia",
    "en": "Emergency Console",
    "ca": "Consola d'Emergència",
  },

  "funcionalidades-section10-description": {
    "es": "¿Tienes que hacer un cambio delicado en tu Servidor Cloud y te da miedo quedarte sin acceso? No te preocupes, en Clouding tendrás acceso a un teclado y monitor remotos conectados directamente a tu Servidor Cloud.",
    "en": "Have to make a delicate change in your cloud server and afraid of running out of access? Don’t worry, with Clouding you’ll have access to a remote keyboard and monitor connected directly to your Cloud server.",
    "ca": "Has de fer un canvi delicat al teu Servidor Cloud i et fa por quedar-te sense accés? No et preocupis, a Clouding tindràs accés a un teclat i monitor remots connectats directament al teu Servidor Cloud.",
  },

  // IMÁGENES
  "sistemas-operativos": {
    "es": "Sistemas Operativos",
    "en": "Operating Systems",
    "ca": "Sistemes Operatius",
  },

  "imagenes-section1-subtitle": {
    "es": "Escoge el sistema operativo que más te convenga, tendrás todas las versiones y<br />actualizaciones disponibles",
    "en": "Choose the operating system that suits you most, all of the versions and updates will be available",
    "ca": "Tria el sistema operatiu que més et convingui, tindràs totes les versions i<br /> actualitzacions disponibles",
  },

  "imagenes-section1-subtitle-mobile": {
    "es": "Escoge el sistema operativo que más te convenga, tendrás todas las versiones y actualizaciones disponibles",
    "en": "Choose the operating system that suits you most, all of the versions and updates will be available",
    "ca": "Tria el sistema operatiu que més et convingui, tindràs totes les versions i actualitzacions disponibles",
  },

  "ver-mas": {
    "es": "Ver más",
    "en": "See more",
    "ca": "Veure més",
  },

  "os-disclaimer": {
    "es": "Ofrecemos licencias para software de Microsoft.",
    "en": "We offer licenses for Microsoft software.",
    "ca": "Oferim llicències per a software de Microsoft.",
  },

  "os-disclaimer-link": {
    "es": "Ver más sobre licencias aquí",
    "en": "See more about licenses here",
    "ca": "Veure més sobre llicències aquí",
  },

  "imagenes-section2-title": {
    "es": "¿No encuentras lo que está buscando?",
    "en": "Can’t find what you’re looking for?",
    "ca": "No trobes el que està buscant?",
  },

  "imagenes-section2-subtitle": {
    "es": "Si te queda alguna duda sobre las soluciones que puedes instalar en tu VPS o sobre<br />cualquier otra cuestión, ¡aquí estamos para ayudarte, contacta con nosotros!",
    "en": "If you have any questions about the solutions you can install on your VPS or anything<br />else, we’re right here to help, just get in touch!",
    "ca": "Si et queda algun dubte sobre les solucions que pots instal·lar al teu VPS o sobre<br />qualsevol altra qüestió, aquí estem per ajudar-te, contacta amb nosaltres!",
  },

  "imagenes-section2-subtitle-mobile": {
    "es": "Si te queda alguna duda sobre las soluciones que puedes instalar en tu VPS o sobre cualquier otra cuestión, ¡aquí estamos para ayudarte, contacta con nosotros!",
    "en": "If you have any questions about the solutions you can install on your VPS or anything else, we’re right here to help, just get in touch!",
    "ca": "Si et queda algun dubte sobre les solucions que pots instal·lar al teu VPS o sobre qualsevol altra qüestió, aquí estem per ajudar-te, contacta amb nosaltres!",
  },

  "imagenes-section2-button": {
    "es": "¡Consúltanos!",
    "en": "Ask us!",
    "ca": "Consulta'ns!",
  },

  "imagenes-section3-title": {
    "es": "Paneles de control",
    "en": "Control panels",
    "ca": "Panells de control",
  },

  "imagenes-section3-subtitle": {
    "es": "Te ofrecemos una variedad de paneles de control preinstalados para que los apliques de manera fácil o, si lo prefieres, podrás instalar tu propio panel de control",
    "en": "We have a variety of preinstalled control panels on offer which you can apply easily or, if you prefer, you can install your own control panel",
    "ca": "T'oferim una varietat de panells de control preinstal·lats perquè els apliquis de manera fàcil o, si ho prefereixes, podràs instal·lar el teu propi panell de control",
  },

  "software": {
    "es": "Software",
    "en": "Software",
    "ca": "Software",
  },

  "imagenes-section4-subtitle": {
    "es": "En nuestra plataforma, encontrarás multitud de aplicaciones para instalar en tu servidor.<br />Todas estas, ¡y muchas más!",
    "en": "On our platform you’ll find a bunch of applications to install on your server.<br />All these, and many more!",
    "ca": "A la nostra plataforma, trobaràs multitud d'aplicacions per a instal·lar al teu servidor.<br /> Totes aquestes, i moltes més!",
  },

  // INFRAESTRUCTURA

  "infraestructura-section1-title": {
    "es": "Alta Disponibilidad",
    "en": "High Availability",
    "ca": "Alta Disponibilitat",
  },

  "infraestructura-section1-description": {
    "es": "Nos encargamos de que tus Servidores Cloud estén siempre protegidos ante posibles fallos. Nuestra plataforma es altamente eficiente para que tú solo tengas que disfrutarla, ¡sin más!",
    "en": "We make sure your cloud servers are protected against possible failures. Our platform is highly efficient so you can just enjoy it, without any further issues!",
    "ca": "Ens encarreguem de que els teus Servidors Cloud estiguin sempre protegits davant de possibles fallades. La nostra plataforma és altament eficient perquè tu només hagis de gaudir-la, sense més! ",
  },

  "infraestructura-section2-title": {
    "es": "100% Redundado",
    "en": "100% Redundant",
    "ca": "100% Redundat",
  },

  "infraestructura-section2-description": {
    "es": "En Clouding todo componente está siempre respaldado por otro listo para entrar en acción en caso de un fallo. Hemos creado una plataforma 100% resiliente y tolerante a errores, donde si ocurre cualquier problema tú ni lo notas.",
    "en": "At Clouding every component is always backed up by another one, ready to take control in case of failure. We have created a 100% resilient and error-tolerant, so if any problem occurs you won’t even notice it.",
    "ca": "A Clouding tot component està sempre recolzat per un altre llest per entrar en acció en cas d'una fallada. Hem creat una plataforma 100% resilient i tolerant a errors, on si passa qualsevol problema ni ho notes.",
  },

  "infraestructura-section3-title": {
    "es": "Triple Réplica",
    "en": "Triple Replica",
    "ca": "Triple Rèplica",
  },

  "infraestructura-section3-description": {
    "es": "Pase lo que pase, garantizamos un espacio de almacenamiento seguro para tus proyectos. Tus datos siempre estarán alojados en tres servidores distintos, ¡perfectamente conservados!",
    "en": "Whatever happens, we’ll always guarantee a secure storage space for your projects. Your data will always be hosted on three different servers, safe and sound!",
    "ca": "Passi el que passi, garantim un espai d'emmagatzematge segur per als teus projectes. Les teves dades sempre estaran allotjades en tres servidors diferents, perfectament conservats! ",
  },

  "infraestructura-section4-title": {
    "es": "Rendimiento Garantizado",
    "en": "Guaranteed Performance",
    "ca": "Rendiment Garantitzat",
  },

  "infraestructura-section4-description": {
    "es": "Controlamos y monitorizamos todos los elementos de la plataforma, para darte siempre el mejor rendimiento: con velocidad constante, sin saturaciones ni sorpresas.",
    "en": "We control and monitor all the elements of the platform, to always give you the best performance: with constant speed, and no saturations or surprises.",
    "ca": "Controlem i monitoritzem tots els elements de la plataforma, per a donar-te sempre el millor rendiment: amb velocitat constant, sense saturacions ni sorpreses.",
  },

  "cpu": {
    "es": "Data Center CPUs",
    "en": "Data Center CPUs",
    "ca": "Data Center CPUs",
  },

  "cpu-details": {
    "es": "La mejor gama de procesadores a tu alcance: eficientes y potentes.",
    "en": "The greatest range of processors at your fingertips: efficient and powerful.",
    "ca": "La millor gamma de processadors al teu abast: eficients i potents.",
  },

  "baja-latencia": {
    "es": "Baja Latencia",
    "en": "Low Latency",
    "ca": "Baixa Latència",
  },

  "baja-latencia-details": {
    "es": "Transmisión de paquetes de datos siempre rápida y sin esperas.",
    "en": "Data packet transmission that’s always fast and without delays.",
    "ca": "Transmissió de paquets de dades sempre ràpida i sense esperes.",
  },

  "green-power": {
    "es": "Green Power",
    "en": "Green Power",
    "ca": "Green Power",
  },

  "green-power-details": {
    "es": "Plataforma optimizada para ser respetuosa con el medioambiente.",
    "en": "Platform optimized to be environmentally friendly.",
    "ca": "Plataforma optimitzada per ser respectuosa amb el medi ambient.",
  },

  "ip-publica": {
    "es": "IP Pública",
    "en": "Public IP",
    "ca": "IP Pública",
  },

  "ip-publica-details": {
    "es": "Consigue tu IP Pública en nuestro sistema: única y solo para ti.",
    "en": "Get your public IP on our system: unique and only for you.",
    "ca": "Aconsegueix la teva IP pública al nostre sistema: única i només per a tu.",
  },

  "80-gbps": {
    "es": "Red a 80 Gbps",
    "en": "80 Gbps Network",
    "ca": "Xarxa a 80 Gbps",
  },

  "80-gbps-details": {
    "es": "Disfruta de tu VM con amplio ancho de banda para tus proyectos.",
    "en": "Enjoy your VM with wide bandwidth for your projects.",
    "ca": "Gaudeix de la teva VM amb gran ample de banda per als teus projectes.",
  },

  "ecc-ram": {
    "es": "ECC RAM",
    "en": "ECC RAM",
    "ca": "ECC RAM",
  },

  "ecc-ram-details": {
    "es": "La memoria de tu servidor siempre estará libre de errores.",
    "en": "Your server memory will always be error free.",
    "ca": "La memòria del teu servidor sempre estarà lliure d'errors.",
  },

  "infraestructura-section5-title": {
    "es": "Anti DDOS",
    "en": "Anti DDOS",
    "ca": "Anti DDOS",
  },

  "infraestructura-section5-description": {
    "es": "¡Fuera intrusos y ataques indeseados! Configuramos y aplicamos las mejores medidas de seguridad para detectar IPs y patrones de tráfico poco fiables y eliminamos ese tráfico antes de que llegue a tu servidor en la Nube.",
    "en": "Intruders and unwanted attacks out! We set up and apply the strongest security measures to detect unreliable IPs and traffic patterns and eliminate that traffic before it reaches your server in the Cloud.",
    "ca": "Fora intrusos i atacs indesitjats! Configurem i apliquem les millors mesures de seguretat per a detectar IPs i patrons de trànsit poc fiables i eliminem aquest trànsit abans que arribi al teu servidor al Núvol.",
  },

  "infraestructura-section6-title": {
    "es": "Data Center Tier IV",
    "en": "Data Center Tier IV",
    "ca": "Data Center Tier IV",
  },

  "infraestructura-section6-description": {
    "es": "Te ofrecemos el mejor alojamiento en uno de los Data Center más avanzados: diseñado y mantenido para que nada falle, con los niveles más altos de seguridad y de calidad, y cerca de ti. Y, además, cumpliendo con la normativa ISO27001.",
    "en": "We offer you the best hosting service in one of the most advanced Data Centers: designed and maintained so that nothing fails, with the highest levels of safety and quality, close to you, in compliance with ISO27001 as well.",
    "ca": "T'oferim el millor allotjament a un dels Data Center més avançats: dissenyat i mantingut perquè res falli, amb els nivells més alts de seguretat i de qualitat, i a prop teu. I, a més, complint amb la normativa ISO27001.",
  },


  "infraestructura-section7-title": {
    "es": "SSD NVMe Enterprise",
    "en": "SSD NVMe Enterprise",
    "ca": "SSD NVMe Enterprise",
  },

  "infraestructura-section7-description": {
    "es": "Rendimiento y velocidad constantes y a la altura de tus expectativas, sin fallos. Somos conscientes de que la mejor plataforma requiere los mejores componentes.",
    "en": "Constant performance and speed to the highest of your expectations, without failures. We’re totally aware that the best platform requires the best components.",
    "ca": "Rendiment i velocitat constants i a l'alçada de les teves expectatives, sense errors. Som conscients que la millor plataforma requereix els millors components.",
  },

  "infraestructura-section8-title": {
    "es": "KVM Hypervisor",
    "en": "KVM Hypervisor",
    "ca": "KVM Hypervisor",
  },

  "infraestructura-section8-description": {
    "es": "La solución de virtualización más completa, segura y flexible que mantiene y asegura instancias separadas para cada uno de tus servidores.",
    "en": "The most complete, secure and flexible virtualization solution that maintains and ensures separate instances for each of your servers.",
    "ca": "La solució de virtualització més completa, segura i flexible que manté i assegura instàncies separades per a cadascun dels teus servidors.",
  },


  // VENTAJAS
  "ventajas-section1-title": {
    "es": "Facturación por horas",
    "en": "Billed by the hour",
    "ca": "Facturació per hores",
  },

  "ventajas-section1-description": {
    "es": "Paga por horas, no por meses. Y disfruta de los recursos que realmente necesites. Nos adaptamos a ti, también en la facturación de nuestro servicio.",
    "en": "Pay by the hour, not by the month. And enjoy the resources that you really need. We adapt to you, also in the billing of our service.",
    "ca": "Paga per hores, no per mesos. I gaudeix dels recursos que realment necessites. Ens adaptem a tu, també en la facturació del nostre servei.",
  },

  "ventajas-section2-title": {
    "es": "Alta Disponibilidad",
    "en": "High Availability",
    "ca": "Alta Disponibilitat",
  },

  "ventajas-section2-description": {
    "es": "Tus Servidores Cloud siempre estarán disponibles y operativos en nuestra plataforma redundante y de altas prestaciones. En Clouding nos aseguramos de que ningún fallo pueda estropear tu día a día.",
    "en": "Your cloud servers will always be available and operational on our high-performance redundant platform. At Clouding we make sure that no fault can ruin your day.",
    "ca": "Els teus Servidors Cloud sempre estaran disponibles i operatius a la nostra plataforma redundant i d'altes prestacions. A Clouding ens assegurem que cap fallada pugui espatllar el teu dia a dia.",
  },

  "ventajas-section3-title": {
    "es": "Soporte de calidad y cercano",
    "en": "High-quality and personal support",
    "ca": "Suport de qualitat i proper",
  },

  "ventajas-section3-description": {
    "es": "Nos sentimos satisfechos cuando todas tus dudas quedan resueltas. Así que nos esforzamos por aclararte todas las preguntas sobre tu servidor, ¡nuestro equipo está a tu lado 24/7!",
    "en": "We feel satisfied when all your doubts are resolved, so we aim to clarify any questions you may have about your server: our team is right here, 24/7!",
    "ca": "Ens sentim satisfets quan tots els teus dubtes queden resolts. Així que ens esforcem per aclarir-te totes les preguntes sobre el teu servidor, el nostre equip està al teu costat 24/7!",
  },

  "ventajas-section4-title": {
    "es": "Data Center en Barcelona",
    "en": "Data Center in Barcelona",
    "ca": "Data Center a Barcelona",
  },

  "ventajas-section4-description": {
    "es": "Tus proyectos, cerca de ti. La mejor plataforma construida sobre un centro de datos Tier IV de proximidad. Con la mayor seguridad, cumpliendo con la normativa ISO27001 y con el nivel de calidad que te mereces.",
    "en": "Your projects, close to you. The best platform built on a Tier IV proximity data center, with the greatest security, complying with ISO27001 and with the level of quality you deserve.",
    "ca": "Els teus projectes, a prop teu. La millor plataforma construïda sobre un centre de dades Tier IV de proximitat. Amb la major seguretat, complint amb la normativa ISO27001 i amb el nivell de qualitat que et mereixes.",
  },

  "ventajas-section5-title": {
    "es": "Rendimiento Garantizado",
    "en": "Guaranteed Performance",
    "ca": "Rendiment Garantitzat",
  },

  "ventajas-section5-description": {
    "es": "Productividad constante y sin saturación, 100% garantizada. En Clouding siempre habrá potencia disponible para todos tus Servidores Cloud, con la misma velocidad y sin sorpresas. ¡Nos encanta cuidar de tus proyectos!",
    "en": "Constant productivity without saturation, 100% guaranteed. At Clouding there will always be power available for all your cloud servers, with the same speed and without surprises. We love taking care of your projects!",
    "ca": "Productivitat constant i sense saturació, 100% garantida. A Clouding sempre hi haurà potència disponible per a tots els teus Servidors Cloud, amb la mateixa velocitat i sense sorpreses. Ens encanta cuidar dels teus projectes!",
  },

  // CARACTERÍSTICAS HEADERS
  "api-title": {
    "es": "Simplificamos tu día a día",
    "en": "We make your daily life simple",
    "ca": "Simplifiquem el teu dia a dia",
  },

  "api-header-description": {
    "es": "Mejora la experiencia en Clouding conectando tus proyectos a nuestra plataforma. Porque sabemos que juntos, sumamos.",
    "en": "Improve your experience with Clouding by connecting your projects to our platform. Because we know that together, we’re stronger.",
    "ca": "Millora l'experiència a Clouding connectant els teus projectes a la nostra plataforma. Perquè sabem que junts, sumem.",
  },


  // funcionalidades
  "funcionalidades-title": {
    "es": "El Cloud de última<br />generación, a tu alcance",
    "en": "The latest generation of<br />Cloud, at your fingertips",
    "ca": "El Cloud d'última<br />generació, al teu abast",
  },

  "funcionalidades-description": {
    "es": "Todas las características que esperabas de una plataforma Cloud Server y, ¡muchas más! Entra a formar parte de la Comunidad Cloud de última generación y disfruta de todo lo que hemos preparado para ti.",
    "en": "All the features you’ve been hoping for from a cloud server platform. and much, much more! Join the last Cloud Community generation and enjoy everything we have ready for you.",
    "ca": "Totes les característiques que esperaves d'una plataforma Cloud Server i, moltes més! Entra a formar part de la Comunitat Cloud d'última generació i gaudeix de tot el que hem preparat per a tu.",
  },

  // imagenes
  "imagenes-header-title": {
    "es": "Una plataforma de lo más flexible",
    "en": "The most flexible platform",
    "ca": "Una plataforma d'allò més flexible",
  },

  "imagenes-header-description": {
    "es": "Instala todo aquello que necesites para trabajar en tu VPS, a tu medida. En Clouding, encontrarás todas las versiones de sistemas operativos, paneles de control e imágenes.",
    "en": "Install everything you require to work on your VPS, to your needs. At Clouding you’ll find all versions of operating systems, control panels and images.",
    "ca": "Instal·la tot allò que necessitis per a treballar al teu VPS, a la teva mida. A Clouding, trobaràs totes les versions de sistemes operatius, panells de control i imatges.",
  },

  // infraestructura
  "infraestructura-title": {
    "es": "Tus Servidores Cloud, en la mejor infraestructura",
    "en": "Your cloud servers, with the best infrastructure",
    "ca": "Els teus Servidors Cloud, a la millor infraestructura",
  },

  "infraestructura-description": {
    "es": "Porque tu confianza es lo único que nos importa, hemos diseñado una plataforma altamente segura, estable y robusta al 100% y con los mejores componentes. ¡Estamos convencidos de que te encantará!",
    "en": "Because your trust is the only thing that matters to us, we’ve designed a highly secure, stable and 100% robust platform with the best components. We’re absolutely convinced you’ll love it!",
    "ca": "Perquè la teva confiança és l'única cosa que ens importa, hem dissenyat una plataforma altament segura, estable i robusta al 100% i amb els millors components. Estem convençuts que t'encantarà!",
  },

  // ventajas
  "ventajas-title": {
    "es": "La plataforma Cloud que estás buscando",
    "en": "The Cloud platform you’re looking for",
    "ca": "La plataforma Cloud que estàs buscant",
  },

  "ventajas-description": {
    "es": "Si te gusta la adaptabilidad y el mejor servicio, has llegado al lugar correcto. Clouding es tu plataforma Cloud de referencia, a tu medida y diseñada pensando en ti y en tus necesidades.",
    "en": "If you like adaptability and the best service, you’ve come to the right place. Clouding is your number one Cloud platform choice, tailored to you and designed with you and your needs in mind.",
    "ca": "Si t'agrada l'adaptabilitat i el millor servei, has arribat al lloc correcte. Clouding és la teva plataforma Cloud de referència, a la teva mida i dissenyada pensant en tu i en les teves necessitats.",
  },

  "cta": {
    "es": "¡Regístrate y pruébanos!",
    "en": "Sign up and give us a try!",
    "ca": "Registra't i prova'ns!",
  },

  // HOME PAGE
  "home-section1__title": {
    "es": "Servidores configurables facturados por horas",
    "en": "Configurable servers billed by the hour",
    "ca": "Servidors configurables facturats per hores",
  },

  "home-section1__subtitle": {
    "es": "Disfruta de la mejor plataforma para tu VPS y paga solo por los recursos que necesitas",
    "en": "Enjoy the best platform for your VPS and pay only for the resources you need",
    "ca": "Gaudeix de la millor plataforma per al teu VPS i paga només pels recursos que necessites",
  },

  "home-section1__configure": {
    "es": "Configura tu<br />servidor<br />a medida",
    "en": "Configure your<br />server to<br />suit you",
    "ca": "Configura el<br />teu servidor<br />a mida",
  },

  "home-section1__configure--mobile": {
    "es": "Configura tu servidor<br />a medida",
    "en": "Configure your server to<br />suit you",
    "ca": "Configura el teu servidor<br />a mida",
  },

  "home-toggle-left": {
    "es": "PRECIO POR MES",
    "en": "PRICE PER MONTH",
    "ca": "PREU PER MES",
  },

  "home-toggle-left-mobile": {
    "es": "€ POR MES",
    "en": "€ PER MONTH",
    "ca": "€ PER MES",
  },

  "home-toggle-right": {
    "es": "PRECIO POR HORA",
    "en": "PRICE PER HOUR",
    "ca": "PREU PER HORA",
  },

  "home-toggle-right-mobile": {
    "es": "€ POR HORA",
    "en": "€ PER HOUR",
    "ca": "€ PER HORA",
  },

  "home-per-period-month": {
    "es": "/mes",
    "en": "/month",
    "ca": "/mes",
  },

  "home-per-period-hour": {
    "es": "/hora",
    "en": "/hour",
    "ca": "/hora",
  },

  "home-max-hour1": {
    "es": "(máx. ",
    "en": "(max. ",
    "ca": "(màx. ",
  },

  "home-max-hour2": {
    "es": "/mes)",
    "en": "/month)",
    "ca": "/mes)",
  },

  "home-section2__title": {
    "es": "Escoge los recursos que se adaptan a ti",
    "en": "Choose the resources that suit you",
    "ca": "Tria els recursos que s’adapten a tu",
  },

  "home-section2__subtitle": {
    "es": "¡Tú decides! Servidores a tu medida, para todo lo que necesites, sin planes preestablecidos. Crea tu Cloud VPS dinámico y flexible en menos de 30 segundos y, ¡pásate al Clouding!",
    "en": "You decide! Servers to suit you, for everything you need, without pre-established plans. Set up your dynamic and flexible Cloud VPS in less than 30 seconds and come to Clouding!",
    "ca": "Tu decideixes! Servidors a la teva mida per a tot el que necessitis, sense plans preestablerts. Crea el teu Cloud VPS dinàmic i flexible en menys de 30 segons i, passa't al Clouding!",
  },

  "home-licencia": {
    "es": "Licencia",
    "en": "License",
    "ca": "Llicència",
  },

  "home-ram": {
    "es": "RAM",
    "en": "RAM",
    "ca": "RAM",
  },

  "home-ram-group-2": {
    "es": "2GB por vCore",
    "en": "2GB per vCore",
    "ca": "2GB per vCore",
  },

  "home-ram-group-4": {
    "es": "4GB por vCore",
    "en": "4GB per vCore",
    "ca": "4GB per vCore",
  },

  "home-procesador": {
    "es": "Procesador",
    "en": "Processor",
    "ca": "Processador",
  },

  "home-ssd": {
    "es": "SSD NVMe",
    "en": "SSD NVMe",
    "ca": "SSD NVMe",
  },

  "home-ssd2": {
    "es": "con Triple Réplica",
    "en": "with Triple Replica",
    "ca": "amb Triple Rèplica",
  },

  "home-ssd-tooltip": {
    "es": "Descubre nuestro innovador",
    "en": "Discover our innovative",
    "ca": "Descobreix el nostre",
  },

  "home-ssd-tooltip-link": {
    "es": "sistema de almacenamiento distribuido con triple réplica.",
    "en": "triple replica distributed system.",
    "ca": "innovador sistema d'emmagatzematge distribuït amb triple rèplica.",
  },

  "home-continuar": {
    "es": "Continuar",
    "en": "Continue",
    "ca": "Continuar",
  },

  "home-section2__group-additional": {
    "es": "4 TB mensuales de transferencia incluidos.<br />Después, 0,004€/GB o 4€/TB.",
    "en": "4 TB of monthly data transfer included.<br />0.004€/GB or 4€/TB after.",
    "ca": "4 TB mensuals de transferència de dades inclosos.<br />Després, 0,004€/GB o 4€/TB.",
  },

  "home-section2__group-additional-link": {
    "es": "Mira los detalles",
    "en": "Take a look at the details",
    "ca": "Mira els detalls",
  },

  "home-unidad": {
    "es": "Unidad",
    "en": "Unit",
    "ca": "Unitat",
  },

  "home-total": {
    "es": "Total",
    "en": "Total",
    "ca": "Total",
  },

  "home-section3__title": {
    "es": "El Cloud que marca la diferencia",
    "en": "The cloud that makes a difference",
    "ca": "El Cloud que marca la diferència",
  },

  "home-section3__subtitle": {
    "es": "En Clouding, el centro eres tú. Trabajamos para ofrecerte la mejor plataforma",
    "en": "At Clouding, you are the focus, and so we work to offer you the best platform.",
    "ca": "A Clouding, el centre ets tu. Treballem per a oferir-te la millor plataforma",
  },

  "home-section3__card1-title": {
    "es": "Facturación<br />por horas",
    "en": "Billing<br />by hours",
    "ca": "Facturació<br />per hores",
  },

  "home-section3__card1-description": {
    "es": "Olvídate de pagar por un Servidor Cloud que no usas. Paga por horas y sin mínimos mensuales.",
    "en": "Forget about paying for a Cloud Server that you don’t use. Pay by the hour and without monthly minimums.",
    "ca": "Oblida't de pagar per un Servidor Cloud que no uses. Paga per hores i sense mínims mensuals.",
  },

  "facturacion": {
    "es": "Facturación",
    "en": "Billing",
    "ca": "Facturació"
  },

  "home-section3__card2-title": {
    "es": "Alta<br />disponibilidad",
    "en": "High<br />availability",
    "ca": "Alta<br />disponibilitat",
  },

  "home-section3__card2-description": {
    "es": "Tus datos siempre disponibles en todo momento. Cuidamos de tus proyectos como lo harías tú mismo.",
    "en": "Your data is always available at all times. We care about your projects as much as you do.",
    "ca": "Les teves dades sempre disponibles en tot moment. Cuidem dels teus projectes com ho faries tu mateix.",
  },

  "home-section3__card2-cta": {
    "es": "Saber más",
    "en": "Find out more",
    "ca": "Saber més",
  },

  "home-section3__card3-title": {
    "es": "Soporte<br />cerca de ti",
    "en": "Support<br />close to you",
    "ca": "Suport<br />prop de tu",
  },

  "home-section3__card3-description": {
    "es": "Si tienes alguna duda con tu Cloud VPS, el equipo de Clouding te ayudará a resolverla. Sin externos.",
    "en": "If you have any questions about your Cloud VPS, our own internal Clouding team is here to help.",
    "ca": "Si tens algun dubte amb el teu Cloud VPS, l'equip de Clouding t'ajudarà a resoldre'l. Sense externs.",
  },

  "home-section3__card3-cta": {
    "es": "Infórmate",
    "en": "Find out more",
    "ca": "Informa't",
  },

  "home-section4__title": {
    "es": "Una plataforma rápida y flexible",
    "en": "A fast and flexible platform",
    "ca": "Una plataforma ràpida i flexible",
  },

  "home-section4__subtitle": {
    "es": "¿Punta de tráfico? Amplía tu servidor en la nube y, ¡qué lluevan las visitas!<br />¿Vuelta a la normalidad? ¡Reduce tu servidor virtual cuando quieras!",
    "en": "Rush hour? Expand your server in the cloud and let those visits come through!<br />Back to normal? Reduce your virtual server whenever you want!",
    "ca": "Punta de trànsit? Amplia el teu servidor al núvol i, què ploguin les visites!<br /> Tornada a la normalitat? Redueix el teu servidor virtual quan vulguis!",
  },

  "home-section4__subtitle-mobile": {
    "es": "¿Punta de tráfico? Amplía tu servidor en la nube y, ¡qué lluevan las visitas! ¿Vuelta a la normalidad? ¡Reduce tu servidor virtual cuando quieras!",
    "en": "Rush hour? Expand your server in the cloud and let those visits come through!<br />Back to normal? Reduce your virtual server whenever you want!",
    "ca": "Punta de trànsit? Amplia el teu servidor al núvol i, què ploguin les visites! Tornada a la normalitat? Redueix el teu servidor virtual quan vulguis!",
  },

  "home-backups": {
    "es": "Backups",
    "en": "Backups",
    "ca": "Backups",
  },

  "home-snapshots": {
    "es": "Snapshots",
    "en": "Snapshots",
    "ca": "Snapshots",
  },

  "home-archivado": {
    "es": "Archivado",
    "en": "Archive",
    "ca": "Arxivat",
  },

  "home-redimensionado": {
    "es": "Redimensionado",
    "en": "Resized",
    "ca": "Redimensionat",
  },

  "home-red-privada": {
    "es": "Red Privada",
    "en": "Private Network",
    "ca": "Xarxa Privada",
  },

  "home-api": {
    "es": "API",
    "en": "API",
    "ca": "API",
  },

  "home-section5__title": {
    "es": "El lugar que necesitan tus proyectos",
    "en": "The place your projects need",
    "ca": "El lloc que necessiten els teus projectes",
  },

  "home-section5__subtitle": {
    "es": "Un alojamiento Cloud seguro y con el mejor rendimiento. Diseñado para ti por nuestros expertos",
    "en": "A secure Cloud host with the best performance. Designed for you by our experts.",
    "ca": "Un allotjament Cloud segur i amb el millor rendiment. Dissenyat per a tu pels nostres experts",
  },

  "home-hardware": {
    "es": "Hardware",
    "en": "Hardware",
    "ca": "Hardware",
  },

  "home-software": {
    "es": "Software",
    "en": "Software",
    "ca": "Software",
  },

  "home-red": {
    "es": "Red",
    "en": "Network",
    "ca": "Xarxa",
  },

  "home-imagenes": {
    "es": "Imágenes",
    "en": "Images",
    "ca": "Imatges",
  },

  "home-data-center": {
    "es": "Data Center",
    "en": "Data Center",
    "ca": "Data Center",
  },

  "home-section7__title": {
    "es": "Ellos ya nos han probado y están encantados",
    "en": "These are a few clients who’ve already tried us out and they love it",
    "ca": "Ells ja ens han provat i estan encantats",
  },

  "home-section7__subtitle": {
    "es": "Tu satisfacción es nuestro mayor regalo. ¡Súmate a la Comunidad Clouding!",
    "en": "Your satisfaction is our greatest gift. Join the Clouding Community!",
    "ca": "La teva satisfacció és el nostre major regal. Suma't a la Comunitat Clouding!",
  },



  "home-section8__cta": {
    "es": "¡Regístrate ahora!",
    "en": "Sign up now!",
    "ca": "Registra't ara!",
  },

  // Section 9
  "home-section9__title": {
    "es": "¿Te queda alguna duda?",
    "en": "Any questions at all?",
    "ca": "Et queda algun dubte?",
  },

  "home-section9__subtitle": {
    "es": "¡Aquí encontrarás la respuesta!",
    "en": "We’ll try to answer them here!",
    "ca": "Aquí trobaràs la resposta!",
  },

  "home-section9__link": {
    "es": "¿No ves tu pregunta? Ve a la página FAQ completa",
    "en": "Can’t find your question? Go to the full FAQ",
    "ca": "No veus la teva pregunta? Vés a la pàgina FAQ completa",
  },

  // HOME - FAQ
  "question1": {
    "es": "¿Ofrecéis Servidores VPS Administrados?",
    "en": "Do you offer Managed VPS Servers?",
    "ca": "Oferiu Servidors VPS Administrats?",
  },

  "answer1": {
    "es": `En lugar de ofrecerte lo que tradicionalmente se entiende como Servidores VPS administrados, te ofrecemos nuestro
          servicio de Cloud Pros.¡Los expertos de Clouding a tu disposición!<br /> <br />

          Nuestro servicio de Cloud Pros se factura en fracciones de media hora, con un precio muy ajustado. De esta forma
          podemos atender todas tus necesidades sin excepción, sin tener que contestarte nunca, que una u otra actuación no
          está incluida en el servicio de VPS administrado que has contratado.<br /> <br />

          ¿Necesitas una versión especial de PHP y tenemos que compilarla solo para ti? Lo haremos. ¿Necesitas que
          instalemos un software complejo? Lo haremos. ¿Han hackeado tu servidor y necesitas que hagamos una limpieza a
          fondo y evaluemos vulnerabilidades? No hay problema.<br /> <br />

          Y si quieres hacerlo tú mismo, nuestro servicio de soporte te ayudará y guiará sin coste alguno.`,
    "en": `Instead of offering you what is traditionally understood as managed VPS Servers, we offer you our Cloud Pros service. The Clouding experts at your disposal! <br /> <br />

          Our Cloud Pros service is billed in fractions of half an hour, with a very reasonable price. So we can meet all your needs without exception, without ever having to answer you, that one or another action isn’t included in the managed VPS service you’ve hired.<br /> <br />

          Do you need a special version of PHP and we can compile it just for you ? We’ll do it. Do you need us to install complex software? We’ll do it. Your server’s been hacked and you need us to clean up and assess vulnerabilities? No problem. <br /> <br />

          And if you want to do it yourself, our support service will help and guide you through it at no cost.`,
    "ca": `En lloc d'oferir-te el que tradicionalment s'entén com a Servidors VPS administrats, t'oferim el nostre
          servei de Cloud Pros. Els experts de Clouding a la teva disposició!<br /><br />

          El nostre servei de Cloud Pros es factura en fraccions de mitja hora, amb un preu molt ajustat. D'aquesta forma
          podem atendre totes les teves necessitats sense excepció, sense haver de contestar-te mai que una o altra actuació no
          està inclosa en el servei de VPS administrat que has contractat.<br /><br />

          Necessites una versió especial de PHP i hem de compilar-la només per a tu? Ho farem. Necessites que
          instal·lem un software complex? Ho farem. Han entrat al teu servidor i necessites que fem una neteja a fons
          i avaluem vulnerabilitats? No hi ha problema.<br /><br />

          I si vols fer-ho tu mateix, el nostre servei de suport t'ajudarà i guiarà sense cap cost.`,
  },

  "question2": {
    "es": "¿Cloud o VPS? ¿Hosting VPS o Servidor Dedicado?",
    "en": "Cloud or VPS? VPS Hosting or Dedicated Server?",
    "ca": "Cloud o VPS? Hosting VPS o Servidor Dedicat?",
  },

  "answer2": {
    "es": `En nuestra web verás que nos referimos al servicio tanto como Servidores Cloud como Servidores VPS, pero nuestra
          plataforma te ofrece lo mejor de cada uno de estos servicios.<br /> <br />

          Te ofrecemos la potencia de un Servidor Dedicado, pero con la flexibilidad y fiabilidad del cloud. Ya que, por
          ejemplo, puedes ampliar o reducir la potencia - y coste - de tu servidor en cualquier momento.<br /> <br />

          Te ofrecemos la facilidad de uso y coste ajustado de los Servidores VPS, pero con toda la potencia, estabilidad y
          seguridad de los Servidores Cloud: ya que toda la infraestructura que hace funcionar tu servidor está redundada,
            de forma que cualquier fallo de hardware en cualquier componente no afecte al servicio que te ofrecemos.<br /> <br />

          Puedes pensar en nuestro servicio como un Servidor VPS supervitaminado, un Servidor Dedicado más flexible o un
          Servidor Cloud asequible y fácil de utilizar.<br /> <br />

          Uno de nuestros objetivos es unificar VPS, Dedicados y Cloud en un solo servicio, para que no tengas que renunciar
          a ninguna ventaja. ¡Así de fácil!`,
    "en": `On our website you’ll see that we refer to the service as both cloud servers and VPS Servers, but our platform offers you the best of both of these services. <br /> <br />

          We offer you the power of a Dedicated Server, but with the flexibility and reliability of the cloud.Since, for example, you can expand or reduce the power - and cost - of your server at any time. <br /> <br />

          We offer you the ease of use and great cost of VPS Servers, but with all the power, stability and Cloud Server security: since all the infrastructure that runs your server is backed up, so that any hardware failure of any component does not affect the service we offer. <br /> <br />

          You can think of our service as a supercharged VPS Server, a more flexible Dedicated Server or a affordable and easy to use cloud server. <br /> <br />

          One of our goals is to unite VPS, Dedicated and Cloud in a single service, so you don't have to give up any of the advantages. It's that easy!`,
    "ca": `A la nostra web veuràs que ens referim al servei tant com a Servidors Cloud com a Servidors VPS, però la nostra
          plataforma t'ofereix el millor de cadascun d'aquests serveis.<br /><br />

          T'oferim la potència d'un Servidor Dedicat, però amb la flexibilitat i fiabilitat del cloud. Ja que, per exemple
          , pots ampliar o reduir la potència -i cost- del teu servidor en qualsevol moment.<br /><br />

          T'oferim la facilitat d'ús i cost ajustat dels Servidors VPS, però amb tota la potència, estabilitat i
          seguretat dels Servidors Cloud: ja que tota la infraestructura que fa funcionar el teu servidor està redundada,
          de manera que qualsevol fallada de hardware en qualsevol component no afecti el servei que t'oferim.<br /><br />

          Pots pensar en el nostre servei com en un Servidor VPS supervitaminat, un Servidor Dedicat més flexible o un
          Servidor Cloud assequible i fàcil d'utilitzar.<br /><br />

          Un dels nostres objectius és unificar VPS, Dedicats i Cloud en un sol servei, perquè no hagis de renunciar
          a cap avantatge. Així de fàcil!`,
  },

  "question3": {
    "es": "¿Ofrecéis Licencias para Plesk, Cpanel o similares?",
    "en": "Do you offer Licenses for Plesk, Cpanel or similar?",
    "ca": "Oferiu Llicències per a Plesk, Cpanel o similars?",
  },

  "answer3": {
    "es": `Nuestro objetivo es ofrecerte los mejores servidores en la nube, por eso todos nuestros esfuerzos se concentran en
          ese fin y preferimos no ofrecerte productos que puedes adquirir fácilmente en otros proveedores (como serían
          licencias de paneles de control, registro de dominios o certificados SSL).<br /> <br />

          Puedes adquirir tales licencias directamente en las webs de los fabricantes de software o en OpenProvider a mejor
          precio, ya que al registrarte en Clouding te ofrecemos un cupón de descuento para que accedas a descuentos.<br /> <br />

          También te ofrecemos imágenes preinstaladas de paneles de control que no necesitan licencia, así podrás tener tu
          servidor con HestiaCP o Centos Web Panel sin tener que pagar ninguna licencia.`,
    "en": `Our goal is to offer you the best servers in the cloud, that's why all our efforts are focused on that end and we prefer not to offer you products that you can easily get from other suppliers (such as control panel licenses, domain registration or SSL certificates). <br /> <br />

          You can purchase such licenses directly on the websites of software manufacturers or OpenProvider at great prices, since when signing up with Clouding we offer you a discount voucher so you can get better prices. <br /> <br />

          We also offer you pre - installed images of control panels that do not need a license, so you can have your server with HestiaCP or Centos Web Panel without paying for a license.`,
    "ca": `El nostre objectiu és oferir-te els millors servidors en el núvol, per això tots els nostres esforços es concentren en
          aquesta fi i preferim no oferir-te productes que pots adquirir fàcilment en altres proveïdors (com serien
          llicències de panells de control, registre de dominis o certificats SSL).<br /><br />

          Pots adquirir tals llicències directament en les webs dels fabricants de software o a OpenProvider a millor
          preu, ja que en registrar-te a Clouding t'oferim un cupó de descompte perquè accedeixis a descomptes.<br /><br />

          També t'oferim imatges preinstal·lades de panells de control que no necessiten llicència, així podràs tenir el teu
          servidor amb HestiaCP o Centos Web Panel sense haver de pagar cap llicència.`,
  },

  "question4": {
    "es": "¿Ofrecéis licencias de productos Microsoft Windows como Escritorio Remoto o SQL Server?",
    "en": "Do you offer licenses for Microsoft Windows products such as Remote Desktop or SQL Server?",
    "ca": "Oferiu llicències de productes Microsoft Windows com Escriptori Remot o SQL Server?",
  },

  "answer4": {
    "es": `Sí, gracias al acuerdo SPLA (Service Provider Licence Agreement) que mantenemos con Microsoft, podemos ofrecerte
          este tipo de licencias en formato de alquiler mensual.<br /> <br />

          Todos los Servidores VPS Windows que ofrecemos incluyen en el precio la licencia por procesador para el sistema
          operativo, por eso no tienes que preocuparte.Y si necesitas licencias adicionales no dudes en contactar con
          nuestro departamento comercial.<br /> <br />

          En caso de que tu volumen sea muy grande, también podemos ayudarte a dar de alta tu propio contrato SPLA, para que
          puedas adquirir tú mismo las licencias que necesites.`,
    "en": `Yes, thanks to the SPLA (Service Provider License Agreement) we have with Microsoft, we can offer you this type of license in a monthly rental format. <br /> <br />

          All the Windows VPS Servers that we offer include the processor license for the operating system in the price, so you don't have to worry. And if you need additional licenses do not hesitate to contact our commercial department. <br /> <br />

          If your volume is very large, we can also help you register your own SPLA contract, so that you can purchase the licenses you need yourself.`,
    "ca": `Sí, gràcies a l'acord SPLA (Service Provider Licence Agreement) que mantenim amb Microsoft, podem oferir-te
          aquest tipus de llicències en format de lloguer mensual.<br /><br />

          Tots els Servidors VPS Windows que oferim inclouen en el preu la llicència per processador per al sistema
          operatiu, per això no has de preocupar-te. I si necessites llicències addicionals no dubtis a contactar amb
          el nostre departament comercial.<br /><br />

          En cas que el teu volum sigui molt gran, també podem ajudar-te a donar d'alta el teu propi contracte SPLA, perquè
          puguis adquirir tu mateix les llicències que necessitis.`,
  },

  "question5": {
    "es": "Si apago mi Servidor Cloud VPS ¿tengo que seguir pagando por él?",
    "en": "If I turn off my Cloud VPS Server, do I have to continue paying for it?",
    "ca": "Si apago el meu Servidor Cloud VPS haig de continuar pagant per ell?",
  },

  "answer5": {
    "es": `Desde nuestro panel de control, puedes archivar tu Servidor Cloud VPS en pocos segundos. Al hacerlo tu servidor se
          apagará y se dejará de reservar la CPU y RAM para el mismo en nuestro plataforma.<br /> <br />

          A partir de ese momento, solo pagarás por el espacio en disco que tengas contratado y dejarás de pagar por la CPU
          o RAM de tu servidor.`,
    "en": `From our control panel, you can archive your Cloud VPS Server in a few seconds. By doing so, your server will turn off and stop reserving the CPU and RAM for it on our platform. <br /> <br />

          From that moment, you will only pay for the disk space you have contracted and you’ll stop paying for the CPU or RAM of your server.`,
    "ca": `Des del nostre panell de control, pots arxivar el teu Servidor Cloud VPS en pocs segons. En fer-ho el teu servidor s'apagarà
          i es deixarà de reservar la CPU i RAM per al mateix en la nostra plataforma.<br /><br />

          A partir d'aquest moment, només pagaràs per l'espai en disc que tinguis contractat i deixaràs de pagar per la CPU
          o RAM del teu servidor.`,
  },

  "question6": {
    "es": "¿Mi servidor tiene IP Española? ¿Dónde está alojado? ¿La IP es fija?",
    "en": "Does my server have a Spanish IP? Where is it hosted? Is the IP fixed?",
    "ca": "El meu servidor té IP Espanyola? On està allotjat? La IP és fixa?",
  },

  "answer6": {
    "es": `Nuestra plataforma se encuentra alojada en la zona industrial de Barcelona y todas las direcciones IP se
          encuentran geolocalizadas en España.<br /> <br />

          Todos nuestros Servidores Cloud cuentan con IP fija pública, la cual estará asignada en exclusiva a tu servidor
          hasta que lo elimines.<br /> <br />

          Al encontrarse nuestra plataforma físicamente en Barcelona, intercambiamos tráfico con todos los operadores
          nacionales en diferentes puntos de España: ofreciéndote así los mejores tiempos de respuesta y las velocidades más
          elevadas, sin descuidar en ningún momento nuestra conectividad internacional. Para que tus contenidos siempre sean
          accesibles a la mayor velocidad desde cualquier punto del planeta.`,
    "en": `Our platform is hosted in the industrial zone of Barcelona and all IP addresses can be geolocated in Spain. <br /> <br />

          All our cloud servers have a fixed public IP, which will be assigned exclusively to your server until you delete it. <br /> <br />

          Since our platform is physically located in Barcelona, we exchange traffic with all national operators in different parts of Spain,
          offering you the best response times and the highest speeds, without ever neglecting our international connectivity. So your content is always accessible at the highest speed from anywhere on the planet.`,
    "ca": `La nostra plataforma es troba allotjada a la zona industrial de Barcelona i totes les adreces IP es troben
          geolocalitzades a Espanya.<br /><br />

          Tots els nostres Servidors Cloud compten amb IP fixa pública, la qual estarà assignada en exclusiva al teu servidor
          fins que l'eliminis.<br /><br />

          En trobar-se la nostra plataforma físicament a Barcelona, intercanviem trànsit amb tots els operadors
          nacionals en diferents punts d'Espanya: oferint-te així els millors temps de resposta i les velocitats més
          elevades, sense descuidar en cap moment la nostra connectivitat internacional. Perquè els teus continguts sempre siguin
          accessibles a la major velocitat des de qualsevol punt del planeta.`,
  },

  // HOME - form circle tabs
  // DATA CENTER
  "data-center-title": {
    "es": "Data Center",
    "en": "Data Center",
    "ca": "Data Center",
  },

  "data-center-description": {
    "es": "Una infraestructura sin puntos de fallo: con centro de datos en España y con los mejores hardware y software para darte el servicio más estable.",
    "en": "An infrastructure without points of failure: with a data center in Spain and with the best hardware and software to give you the most stable service.",
    "ca": "Una infraestructura sense punts de fallada: amb centre de dades a Espanya i amb el millor hardware i software per a donar-te el servei més estable.",
  },

  "data-center-link": {
    "es": "¡Más info aquí!",
    "en": "More info here!",
    "ca": "Més info aquí!",
  },

  "data-center-section1-title": {
    "es": "En España",
    "en": "In Spain",
    "ca": "A Espanya",
  },

  "data-center-section1-description": {
    "es": "A tu lado, nos gusta ser próximos y estar cerca de ti",
    "en": "By your side, we like to be close and personal to you",
    "ca": "Al teu costat, ens agrada ser pròxims i estar a prop teu",
  },

  "data-center-section2-title": {
    "es": "Tier IV",
    "en": "Tier IV",
    "ca": "Tier IV",
  },

  "data-center-section2-description": {
    "es": "El estándar más alto de seguridad y disponibilidad para ti",
    "en": "The highest standard of security and availability for you",
    "ca": "L'estàndard més alt de seguretat i disponibilitat per a tu",
  },

  "data-center-section3-title": {
    "es": "100% redundante",
    "en": "100% redundant",
    "ca": "100% redundant",
  },

  "data-center-section3-description": {
    "es": "Solo la mejor infraestructura física puede generar la mejor nube",
    "en": "Only the best physical infrastructure can generate the best cloud",
    "ca": "Només la millor infraestructura física pot generar el millor núvol",
  },

  "data-center-section4-title": {
    "es": "Green Power",
    "en": "Green Power",
    "ca": "Green Power",
  },

  "data-center-section4-description": {
    "es": "Nuestro Data Center funciona con energía 100% renovable",
    "en": "Our Data Center works with 100% renewable energy",
    "ca": "El nostre Data Center funciona amb energia 100% renovable",
  },

  // hardware
  "hardware-title": {
    "es": "Hardware",
    "en": "Hardware",
    "ca": "Hardware",
  },

  "hardware-description": {
    "es": "Los mejores componentes para que tu Cloud VPS vuele, con la máxima fiabilidad y obteniendo grandes resultados. Y, por supuesto, garantizándote fiabilidad 100%.",
    "en": "The best components for your Cloud VPS to take off, with maximum reliability and obtaining great results, and, of course, guaranteeing you 100% reliability.",
    "ca": "Els millors components perquè el teu Cloud VPS voli, amb la màxima fiabilitat i obtenint grans resultats. I, per descomptat, garantint-te fiabilitat 100%.",
  },

  "hardware-link": {
    "es": "Descubre más ahora",
    "en": "Find out more now",
    "ca": "Descobreix més ara",
  },

  "hardware-section1-title": {
    "es": "Data Center CPUs",
    "en": "Data Center CPUs",
    "ca": "Data Center CPUs",
  },

  "hardware-section1-description": {
    "es": "Te ofrecemos Cores más rápidos y eficientes para tu servidor",
    "en": "We offer you the fastest and most efficient Cores for your server",
    "ca": "T'oferim Cores més ràpids i eficients per al teu servidor",
  },

  "hardware-section2-title": {
    "es": "SSD NMVe",
    "en": "SSD NMVe",
    "ca": "SSD NMVe",
  },

  "hardware-section2-description": {
    "es": "La gama más<br />alta de almacenamiento, con el mejor rendimiento",
    "en": "The highest range of storage, with the best performance",
    "ca": "La gamma més<br />alta d'emmagatzematge, amb el millor rendiment",
  },

  "hardware-section3-title": {
    "es": "RAM ECC",
    "en": "RAM ECC",
    "ca": "RAM ECC",
  },

  "hardware-section3-description": {
    "es": "Para que puedas confiar en el resultado de tus cálculos",
    "en": "So you can trust the result of your numbers",
    "ca": "Perquè puguis confiar en el resultat dels teus càlculs",
  },

  "hardware-section4-title": {
    "es": "Tolerante a fallos",
    "en": "Fault tolerant",
    "ca": "Tolerant a incidències",
  },

  "hardware-section4-description": {
    "es": "Solo el mejor hardware puede enfrentarse a todos los imprevistos",
    "en": "Only the best hardware can face the unexpected",
    "ca": "Només el millor hardware pot fer front a tots els imprevistos",
  },

  // Imagenes
  "imagenes-title": {
    "es": "Imágenes",
    "en": "Images",
    "ca": "Imatges",
  },

  "imagenes-description": {
    "es": "Crea tu servidor con nuestro catálogo de imágenes. Accede a todas las aplicaciones que necesites desde tu panel de control, siempre con acceso root.",
    "en": "Create your server with our image catalog. Access all the applications you need from your control panel, always with root access.",
    "ca": "Crea el teu servidor amb el nostre catàleg d'imatges. Accedeix a totes les aplicacions que necessitis des del teu panell de control, sempre amb accés root.",
  },

  "imagenes-link": {
    "es": "¿Quieres saber los detalles?",
    "en": "Want to find out the details?",
    "ca": "Vols saber els detalls?",
  },

  "imagenes-section1-title": {
    "es": "Linux",
    "en": "Linux",
    "ca": "Linux",
  },

  "imagenes-section1-description": {
    "es": "Múltiples versiones disponibles para que puedas escoger",
    "en": "Multiple versions available for you to choose from",
    "ca": "Múltiples versions disponibles perquè puguis triar",
  },

  "imagenes-section2-windows": {
    "es": "Windows",
    "en": "Windows",
    "ca": "Windows",
  },

  "imagenes-section2-description": {
    "es": "Todas las versiones de Windows disponibles",
    "en": "All available versions of Windows",
    "ca": "Totes les versions de Windows disponibles",
  },

  "imagenes-section3-description": {
    "es": "Plesk, HestiaCP, etc. preinstalados o instala tu propio panel de control",
    "en": "Plesk, HestiaCP, etc. preinstalled or install your own control panel",
    "ca": "Plesk, HestiaCP, etc. preinstal·lats o instal·la el teu propi panell de control",
  },

  "apps": {
    "es": "Apps",
    "en": "Apps",
    "ca": "Apps",
  },

  "imagenes-section4-description": {
    "es": "Wordpress, Docker, Magento, Prestashop y muchas otras",
    "en": "Wordpress, Docker, Magento, Prestashop and many more",
    "ca": "Wordpress, Docker, Magento, Prestashop i moltes altres",
  },


  // Red
  "red-title": {
    "es": "Red",
    "en": "Network",
    "ca": "Xarxa",
  },

  "red-description": {
    "es": "Los recursos y la estabilidad que buscas están garantizados en la plataforma de Clouding: amplio ancho de banda, rapidez y un rendimiento increíble.",
    "en": "The resources and stability you’re looking for are guaranteed on the Clouding platform: wide bandwidth, speed and incredible performance.",
    "ca": "Els recursos i l'estabilitat que cerques estan garantits a la plataforma de Clouding: gran ample de banda, rapidesa i un rendiment increïble.",
  },

  "red-link": {
    "es": "Prueba nuestro test de velocidad",
    "en": "Try our speed test",
    "ca": "Prova el nostre test de velocitat",
  },

  "red-section1-title": {
    "es": "Anti DDOS",
    "en": "Anti DDOS",
    "ca": "Anti DDOS",
  },

  "red-section1-description": {
    "es": "La mejor protección que bloquea cualquier ataque a tu servidor",
    "en": "The best protection that blocks any attack on your server",
    "ca": "La millor protecció que bloqueja qualsevol atac al teu servidor",
  },

  "red-section2-title": {
    "es": "80 Gbps",
    "en": "80 Gbps",
    "ca": "80 Gbps",
  },

  "red-section2-description": {
    "es": "Una sólida y potente red CISCO para garantizarte un gran rendimiento",
    "en": "A solid and powerful CISCO network to guarantee great performance",
    "ca": "Una sòlida i potent xarxa CISCO per a garantir-te un gran rendiment",
  },

  "red-section3-title": {
    "es": "Baja Latencia",
    "en": "Low Latency",
    "ca": "Baixa Latència",
  },

  "red-section3-description": {
    "es": "Conectamos con múltiples proveedores para ofrecerte siempre el camino más corto",
    "en": "We connect with multiple providers to always offer you the shortest path",
    "ca": "Connectem amb múltiples proveïdors per a oferir-te sempre el camí més curt",
  },

  "red-section4-title": {
    "es": "IP Pública",
    "en": "Public IP",
    "ca": "IP Pública",
  },

  "red-section4-description": {
    "es": "Una IP fija incluida con cada servidor",
    "en": "A fixed IP included with each server",
    "ca": "Una IP fixa inclosa amb cada servidor",
  },

  // Software
  "software-title": {
    "es": "Software",
    "en": "Software",
    "ca": "Software",
  },

  "software-description": {
    "es": "Diseñamos la mejor plataforma: rápida, segura, potente, a prueba de fallos y que se adapta a ti y a lo que necesitas en cada momento. ¡El Cloud de última generación!",
    "en": "We designed the best platform: fast, safe, powerful, failsafe and that adapts to you and what you need at all times. The cloud of the latest generation!",
    "ca": "Dissenyem la millor plataforma: ràpida, segura, potent, a prova de fallades i que s'adapta a tu i al que necessites a cada moment. El Cloud d'última generació!",
  },

  "software-link": {
    "es": "Clica aquí para saber más",
    "en": "Click here to find out more",
    "ca": "Clica aquí per a saber més",
  },

  "software-section1-title": {
    "es": "KVM Hypervisor",
    "en": "KVM Hypervisor",
    "ca": "KVM Hypervisor",
  },

  "software-section1-description": {
    "es": "Para garantizarte rendimiento y disponibilidad",
    "en": "To guarantee performance and availability",
    "ca": "Per a garantir-te rendiment i disponibilitat",
  },

  "software-section2-title": {
    "es": "Cloud Storage",
    "en": "Cloud Storage",
    "ca": "Cloud Storage",
  },

  "software-section2-description": {
    "es": "Tus datos replicados tres veces en nuestro Ceph Cluster",
    "en": "Your data replicated three times in our Ceph Cluster",
    "ca": "Les teves dades replicades tres vegades en el nostre Ceph Cluster",
  },

  "software-section3-title": {
    "es": "Monitorización",
    "en": "Monitoring",
    "ca": "Monitoratge",
  },

  "software-section3-description": {
    "es": "Miles de parámetros monitorizados para adelantarnos a los fallos",
    "en": "Thousands of monitored parameters to anticipate failures",
    "ca": "Milers de paràmetres monitorats per a avançar-nos a les fallades",
  },

  "software-section4-title": {
    "es": "Consola de emergencia",
    "en": "Emergency console",
    "ca": "Consola d'emergència",
  },

  "software-section4-description": {
    "es": "Un teclado y monitor remotos para solucionar cualquier problema",
    "en": "A remote keyboard and monitor to solve any problems",
    "ca": "Un teclat i monitor remots per a solucionar qualsevol problema",
  },

  // HOME - Vertical tab
  "backups": {
    "es": "Backups",
    "en": "Backups",
    "ca": "Backups",
  },

  "backups-description": {
    "es": "Configura y diseña tu sistema de copias de seguridad de manera rápida y sencilla, ¡en segundos! Los datos de tus proyectos siempre estarán a salvo en Clouding, restaura tus Backups cuando quieras y, ¡listo!",
    "en": "Set up and design your backup system quickly and easily, in seconds! Your projects’ data will always be safe at Clouding, restore your Backups whenever you want and you’re ready to go!",
    "ca": "Configura i dissenya el teu sistema de còpies de seguretat de manera ràpida i senzilla, en segons! Les dades dels teus projectes sempre estaran fora de perill a Clouding, restaura els teus Backups quan vulguis i, llest!",
  },

  "backups-cta": {
    "es": "¡Infórmate!",
    "en": "Find out more",
    "ca": "Informa't!",
  },

  "snapshots": {
    "es": "Snapshots",
    "en": "Snapshots",
    "ca": "Snapshots",
  },

  "snapshots-description": {
    "es": "Clona o realiza instantáneas de tu servidor en cualquier momento: antes de realizar una actualización o un cambio importante. Siempre podrás revertir al estado anterior, ¡sin perder ninguna información!",
    "en": "Clone or make instants of your server at any time, before doing an update or making an important change. You can always go back to the previous state, without losing any information!",
    "ca": "Clona o realitza instantànies del teu servidor en qualsevol moment: abans de realitzar una actualització o un canvi important. Sempre podràs revertir a l'estat anterior, sense perdre cap informació!",
  },

  "snapshots-cta": {
    "es": "¿Quieres saber más?",
    "en": "Want to find out more?",
    "ca": "Vols saber més?",
  },

  "archivado": {
    "es": "Archivado",
    "en": "Archive",
    "ca": "Arxivat",
  },

  "archivado-description": {
    "es": "En Clouding, sabemos que la flexibilidad es importante para ti. Por eso, cuando no uses tu servidor puedes archivarlo y ahorrar en costes. Cuando necesites volver a la normalidad, ¡hazlo al instante!",
    "en": "At Clouding, we know that flexibility is important to you. Therefore, when you’re not using your server you can archive it and save on costs. When you need to go back to normal, do it straightaway!",
    "ca": "A Clouding, sabem que la flexibilitat és important per a tu. Per això, quan no usis el teu servidor pots arxivar-lo i estalviar en costos. Quan necessitis tornar a la normalitat, fes-ho a l'instant!",
  },

  "archivado-cta": {
    "es": "¡Aquí tienes más info!",
    "en": "More info here!",
    "ca": "Aquí tens més info!",
  },

  "redimensionado": {
    "es": "Redimensionado",
    "en": "Resized",
    "ca": "Redimensionat",
  },

  "redimensionado-description": {
    "es": "Cambia los recursos de tus servidores en el momento que lo desees, tantas veces como quieras. Porque sabemos que tus necesidades no siempre son las mismas, en Clouding aumenta o disminuye en segundos.",
    "en": "Change your servers’ resources whenever you want, as many times as you want. Here at Clouding we know that your needs are not always the same, so you can increase or decrease it in seconds.",
    "ca": "Canvia els recursos dels teus servidors en el moment que ho desitgis, tantes vegades com vulguis. Perquè sabem que les teves necessitats no sempre són les mateixes, a Clouding augmenta o disminueix en segons.",
  },

  "redimensionado-cta": {
    "es": "¿Tienes más curiosidad?",
    "en": "Curious to find out more?",
    "ca": "Tens més curiositat?",
  },

  "red-privada": {
    "es": "Red Privada",
    "en": "Private Network",
    "ca": "Xarxa Privada",
  },

  "red-privada-description": {
    "es": "Tus datos están seguros con nosotros. Y siempre podrás administrarlos de manera fácil y ágil, con el mayor rendimiento y la mejor potencia que te da una VLAN Dedicada. ¡Mejor, imposible!",
    "en": "Your data is safe with us, and you can always manage it easily and quickly, with the highest performance and the best power that a Dedicated VLAN gives you. There’s no better way!",
    "ca": "Les teves dades estan segures amb nosaltres. I sempre podràs administrar-les de manera fàcil i àgil, amb el major rendiment i la millor potència que et dóna una VLAN Dedicada. Millor, impossible!",
  },

  "red-privada-cta": {
    "es": "Más información",
    "en": "More information",
    "ca": "Més informació",
  },

  "api-description": {
    "es": "Conecta tu software a la plataforma de Clouding sin problemas. Intercambia información de manera rápida y sencilla, simplifica tu trabajo y, ¡mejora la experiencia Clouding!",
    "en": "Connect your software to the Clouding platform without any problems. Exchange information quickly and easily, simplify your work and improve the Clouding experience!",
    "ca": "Connecta el teu software a la plataforma de Clouding sense problemes. Intercanvia informació de manera ràpida i senzilla, simplifica la teva feina i, millora l'experiència Clouding!",
  },

  "api-cta": {
    "es": "Lee más aquí",
    "en": "Read more here",
    "ca": "Llegeix més aquí",
  },

  // HOME - header
  "home-header__title": {
    "es": "Infraestructura Cloud a tu servicio",
    "en": "Cloud Infrastructure at your service",
    "ca": "Infraestructura Cloud al teu servei",
  },

  "home-header__subtitle": {
    "es": "Servidores Cloud potentes, estables y flexibles. Mucho más que máquinas virtuales",
    "en": "Powerful, stable and flexible cloud servers. Much more than virtual machines!",
    "ca": "Servidors Cloud potents, estables i flexibles. Molt més que màquines virtuals",
  },

  "home-header__sign-up-free-text": {
    "es": "¡5€ de saldo GRATIS al registrarte!",
    "en": "€5 FREE balance when you sign up!",
    "ca": "5€ de saldo GRATIS en registrar-te!",
  },

  "home-header__sign-up-title": {
    "es": "Pruébanos gratis, valdrá la pena",
    "en": "Try us out for free, it’ll be worth it!",
    "ca": "Prova'ns gratis, valdrà la pena",
  },



  "home-header__start-button": {
    "es": "Crea tu cuenta gratis",
    "en": "Create a free account",
    "ca": "Crea el teu compte gratis",
  },

  // NOSOTROS PAGE
  "nosotros-section1__title": {
    "es": "Conoce más sobre Clouding",
    "en": "Find out more about Clouding",
    "ca": "Coneix més sobre Clouding",
  },

  "nosotros-section1__subtitle": {
    "es": "Cercanos, creativos, con más de 15 años de experiencia en el sector,<br /> ¡y con mucha pasión por nuestro trabajo!",
    "en": "Personal, creative, with more than 15 years of experience in the sector, <br /> and with a lot of passion for our work!",
    "ca": "Pròxims, creatius, amb més de 15 anys d'experiència en el sector,<br /> i amb molta passió per la nostra feina!",
  },

  "nosotros-mision": {
    "es": "Misión",
    "en": "Mission",
    "ca": "Missió",
  },

  "nosotros-mision-description": {
    "es": "Ofrecerte el mejor servicio de infraestructura en la nube, con una atención al cliente excepcional y un coste comedido. Invirtiendo constantemente en nuestra plataforma y equipo humano, para ser siempre tu proveedor de referencia.",
    "en": "To offer you the best infrastructure service in the cloud, with exceptional customer service and at a low cost. We’re constantly investing in our platform and human team, to always be your top provider.",
    "ca": "Oferir-te el millor servei d'infraestructura en el núvol, amb una atenció al client excepcional i un cost moderat. Invertint constantment en la nostra plataforma i equip humà, per ser sempre el teu proveïdor de referència.",
  },

  "nosotros-vision": {
    "es": "Visión",
    "en": "Vision",
    "ca": "Visió",
  },

  "nosotros-vision-description": {
    "es": "Centrar nuestra oferta en aquellos servicios y funcionalidades donde podemos aportar auténtico valor para ti. Desarrollando soluciones potentes, estables y sencillas que se adaptan a tus necesidades.",
    "en": "To focus on those services and functions where we can bring you real value, and develop powerful, stable and simple solutions that adapt to your needs.",
    "ca": "Centrar la nostra oferta en aquells serveis i funcionalitats on podem aportar autèntic valor per a tu. Desenvolupant solucions potents, estables i senzilles que s'adapten a les teves necessitats",
  },

  "nosotros-valores": {
    "es": "Valores",
    "en": "Values",
    "ca": "Valors",
  },

  "nosotros-valores-description": {
    "es": "Relacionarnos siempre desde la honestidad, la transparencia, la pasión y la empatía. Manteniendo siempre un ambiente de trabajo que favorezca la curiosidad, la diversión y la creatividad. Motivados por la satisfacción de ofrecerte siempre calidad, innovación y la mejor atención. ",
    "en": "To always connect with honesty, transparency, passion and empathy. To always keep a work environment that fosters curiosity, fun and creativity. We’re motivated by the satisfaction of always offering you quality, innovation and the best service. ",
    "ca": "Relacionar-nos sempre des de l'honestedat, la transparència, la passió i l'empatia. Mantenint sempre un ambient de treball que afavoreixi la curiositat, la diversió i la creativitat. Motivats per la satisfacció d'oferir-te sempre qualitat, innovació i la millor atenció.",
  },


  "nosotros-metric1-number": {
    "es": "180.000+",
    "en": "180.000+",
    "ca": "180.000+",
  },

  "nosotros-metric1-text": {
    "es": "Servidores creados",
    "en": "Servers created",
    "ca": "Servidors creats",
  },

  "nosotros-metric1-text-mobile": {
    "es": "Servidores<br />creados",
    "en": "Servers<br />created",
    "ca": "Servidors<br />creats",
  },

  "nosotros-metric2-number": {
    "es": "6.500+",
    "en": "6.500+",
    "ca": "6.500+",
  },

  "nosotros-metric2-text": {
    "es": "Clientes satisfechos",
    "en": "Satisfied customers",
    "ca": "Clients satisfets",
  },
  "nosotros-metric2-text-mobile": {
    "es": "Clientes<br />satisfechos",
    "en": "Satisfied<br />customers",
    "ca": "Clients<br />satisfets",
  },

  "nosotros-metric3-number": {
    "es": "99,15%",
    "en": "99,15%",
    "ca": "99,15%",
  },

  "nosotros-metric3-text": {
    "es": "Índice de satisfacción",
    "en": "Satisfaction index",
    "ca": "Índex de satisfacció",
  },

  "nosotros-metric3-text-mobile": {
    "es": "Índice de<br />satisfacción",
    "en": "Satisfaction<br />index",
    "ca": "Índex de<br />satisfacció",
  },

  "nosotros-metric4-number": {
    "es": "1500+",
    "en": "1500+",
    "ca": "1500+",
  },

  "nosotros-metric4-text": {
    "es": "Tickets resueltos al mes",
    "en": "Tickets resolved per month",
    "ca": "Tickets resolts al mes",
  },

  "nosotros-metric4-text-mobile": {
    "es": "Tickets resueltos<br />al mes",
    "en": "Tickets resolved<br />per month",
    "ca": "Tickets resolts<br />al mes",
  },

  "nosotros-header__description-mobile": {
    "es": "Nos mueve el objetivo de darte un servicio del cuál nosotros fuéramos auténticos fans. Somos ingenieros de software y de sistemas y programadores apasionados por crear la mejor plataforma Cloud que puedas encontrar. ¡Nos encanta nuestro trabajo!",
    "en": "We’re excited about the goal of giving you a service which we ourselves were genuine fans of. We’re software and systems engineers and programmers passionate about creating the best Cloud platform you can find. We love our job!",
    "ca": "Ens mou l'objectiu de donar-te un servei del qual nosaltres fóssim autèntics fans. Som enginyers de software i de sistemes i programadors apassionats per crear la millor plataforma Cloud que puguis trobar. Ens encanta la nostra feina!",
  },

  "nosotros-header__title": {
    "es": "¿Qué nos mueve?",
    "en": "What excites us?",
    "ca": "Què ens mou?",
  },

  "nosotros-header__description": {
    "es": "Nos mueve el objetivo de darte un servicio del cuál<br />nosotros fuéramos auténticos fans. Somos ingenieros de<br /> software y de sistemas y programadores apasionados por<br />crear la mejor plataforma Cloud que puedas encontrar.<br /> ¡Nos encanta nuestro trabajo!",
    "en": "We’re excited about the goal of giving you a service which we ourselves were genuine fans of. We’re software and systems engineers and programmers passionate about creating the best Cloud platform you can find. We love our job!",
    "ca": "Ens mou l'objectiu de donar-te un servei del qual<br />nosaltres fóssim autèntics fans. Som enginyers de<br /> software i de sistemes i programadors apassionats per<br />crear la millor plataforma Cloud que puguis trobar.<br /> Ens encanta la nostra feina!",
  },

  "nosotros-history-title": {
    "es": "La historia de Clouding",
    "en": "The Clouding story",
    "ca": "La història de Clouding",
  },

  "nosotros-history-subtitle": {
    "es": "Trabajamos para ti de forma continua mejorando nuestro servicio año tras año sin elevar los<br />costes, pensando en darte lo mejor",
    "en": "We’re continuously working to improve our service year after year without raising costs, thinking about giving you the best",
    "ca": "Treballem per a tu de forma continua millorant el nostre servei any rere any sense elevar els<br />costos, pensant a donar-te el millor",
  },

  "enero-2016": {
    "es": "Enero 2016",
    "en": "January 2016",
    "ca": "Gener 2016",
  },

  "enero-2016-text": {
    "es": "¡Clouding sale al mercado! Ya hemos llegado",
    "en": "Clouding goes on the market! We’ve arrived",
    "ca": "Clouding surt al mercat! Ja hem arribat",
  },

  "mayo-2017": {
    "es": "Mayo 2017",
    "en": "May 2017",
    "ca": "Maig 2017",
  },

  "mayo-2017-text": {
    "es": "¡Más de 4.500 servidores creados!<br />Ofrecemos vCores más potentes y discos más rápidos",
    "en": "More than 4,500 servers created on the platform!<br />More powerful vCores and faster disk.",
    "ca": "Més de 4.500 servidors creats!<br />Oferim vCores més potents i discos més ràpids",
  },

  "junio-2018": {
    "es": "Junio 2018",
    "en": "June 2018",
    "ca": "Juny 2018",
  },

  "junio-2018-text": {
    "es": "Lanzamos el Archivado de servidores y mejoramos nuestro Anti-DDOS",
    "en": "We launched Server Archiving and improved our Anti-DDOS",
    "ca": "Llancem l'Arxivat de servidors i millorem el nostre Anti-DDOS",
  },

  "octubre-2018": {
    "es": "Octubre 2018",
    "en": "October 2018",
    "ca": "Octubre 2018",
  },

  "octubre-2018-text": {
    "es": "Más de 35.000 servidores creados, ¡triplicamos el ancho de banda!",
    "en": "More than 35,000 servers created, we doubled the bandwidth!",
    "ca": "Més de 35.000 servidors creats, tripliquem l'ample de banda!",
  },


  "abril-2019": {
    "es": "Abril 2019",
    "en": "April 2019",
    "ca": "Abril 2019",
  },

  "abril-2019-text": {
    "es": "Mejoramos la conectividad y aumentamos la potencia de cada vCore",
    "en": "We improved connectivity and increased the power of each vCore",
    "ca": "Millorem la connectivitat i augmentem la potència de cada vCore",
  },

  "noviembre-2020": {
    "es": "Noviembre 2020",
    "en": "November 2020",
    "ca": "Novembre 2020",
  },

  "noviembre-2020-text": {
    "es": "Servidores de hasta 48 vCores y 192 GB de RAM",
    "en": "Servers up to 48 vCores with 192 GB RAM",
    "ca": "Servidors de fins a 48 vCores i 192 GB de RAM",
  },

  "junio-2021": {
    "es": "Junio 2021",
    "en": "June 2021",
    "ca": "Juny 2021",
  },

  "junio-2021-text": {
    "es": "Cuadruplicamos el ancho de banda de nuestro Data Center<br />y doblamos la transferencia de datos mensual a 4TB por servidor.",
    "en": "We quadrupled our Data Center bandwidth and doubled the monthly data transfer to 4TB per server.",
    "ca": "Quadrupliquem l'ample de banda de nostre Data Center<br />i doblem la transferència de dades mensual a 4TB per servidor.",
  },

  "junio-2021-text-mobile": {
    "es": "Cuadruplicamos el ancho de banda de nuestro Data Center y doblamos la transferencia de datos mensual a 4TB por servidor.",
    "en": "We quadrupled our Data Center bandwidth and doubled the monthly data transfer to 4TB per server.",
    "ca": "Quadrupliquem l'ample de banda de nostre Data Center i doblem la transferència de dades mensual a 4TB per servidor.",
  },

  "diciembre-2022": {
    "es": "Diciembre 2022",
    "en": "December 2022",
    "ca": "Desembre 2022",
  },

  "diciembre-2022-text": {
    "es": "Nuevo Anti-DDoS Estricto, ¡un escudo total contra ataques DDoS frecuentes!",
    "en": "New Strict Anti-DDoS, the ultimate shield against frequent DDoS attacks!",
    "ca": "Nou Anti-DDoS Estricte, un escut definitiu contra atacs DDoS freqüents!",
  },

  "marzo-2023": {
    "es": "Marzo 2023",
    "en": "March 2023",
    "ca": "Març 2023",
  },

  "marzo-2023-text": {
    "es": "¡Ya disponible nuestra API en tu panel de cliente!",
    "en": "Our API is now available in your customer panel!",
    "ca": "Ja disponible la nostra API en el teu panell de client!",
  },

  // "enero-2017": {
  //   "es": "Enero 2017",
  //   "en": "January 2017",
  //   "ca": "Gener 2017",
  // },

  // "enero-2017-text": {
  //   "es": "Más de 4.000 servidores creados en la plataforma",
  //   "en": "More than 4,000 servers created on the platform",
  //   "ca": "Més de 4.000 servidors creats a la plataforma",
  // },

  // "enero-2018": {
  //   "es": "Enero 2018",
  //   "en": "January 2018",
  //   "ca": "Gener 2018",
  // },

  // "enero-2018-text": {
  //   "es": "¡Más de 12.000 servidores creados!",
  //   "en": "More than 12,000 servers created!",
  //   "ca": "Més de 12.000 servidors creats!",
  // },


  // "abril-2020": {
  //   "es": "Abril 2020",
  //   "en": "April 2020",
  //   "ca": "Abril 2020",
  // },

  // "abril-2020-text": {
  //   "es": "Seguimos innovando: ¡lanzamos nuestra característica de Disaster Recovery!",
  //   "en": "We keep innovating: we launched our Disaster Recovery feature!",
  //   "ca": "Continuem innovant: llancem la nostra característica de Disaster Recovery!",
  // },





  // SOPORTE PAGE
  "soporte__section1__title": {
    "es": "Soporte&nbsp;24/7 y Cloud&nbsp;Pros",
    "en": "24/7&nbsp;Support and Cloud&nbsp;Pros",
    "ca": "Suport&nbsp;24/7 i Cloud&nbsp;Pros",
  },

  "soporte__section1__description": {
    "es": "Clouding es un servicio autogestionado: nosotros te damos la mejor infraestructura y tu instalas el<br />software en tu VPS. Pero, ¿qué pasa si te surge algún problema? ¡Estamos a tu lado, nuestro equipo<br />de Soporte estará siempre disponible para ti!",
    "en": "Clouding is a self-managed service: we give you the best infrastructure and you install the<br />software on your VPS. But what happens if you have a problem? We’re right here, and our<br />Support team will always be available for you!",
    "ca": "Clouding és un servei autogestionat: nosaltres et donem la millor infraestructura i tu instal·les el<br />software al teu VPS. Però, què passa si et sorgeix algun problema? Estem al teu costat, el nostre equip<br />de Suport estarà sempre disponible per a tu!",
  },

  "soporte__section1__description-mobile": {
    "es": "Clouding es un servicio autogestionado: nosotros te damos la mejor infraestructura y tu instalas el software en tu VPS. Pero, ¿qué pasa si te surge algún problema? ¡Estamos a tu lado, nuestro equipo de Soporte estará siempre disponible para ti!",
    "en": "Clouding is a self-managed service: we give you the best infrastructure and you install the software on your VPS. But what happens if you have a problem? We’re right here, and our Support team will always be available for you!",
    "ca": "Clouding és un servei autogestionat: nosaltres et donem la millor infraestructura i tu instal·les el software al teu VPS. Però, què passa si et sorgeix algun problema? Estem al teu costat, el nostre equip de Suport estarà sempre disponible per a tu!",
  },

  "soporte__vs": {
    "es": "VS",
    "en": "VS",
    "ca": "VS",
  },

  "soporte__soporte-clouding": {
    "es": "Soporte Clouding",
    "en": "Clouding Support",
    "ca": "Suport Clouding",
  },

  "soporte__bullet1": {
    "es": "Te ayudamos a resolver los problemas que puedas tener con el software instalado en tu servidor.",
    "en": "We help you solve any problems you may have with the software installed on your server.",
    "ca": "T'ajudem a resoldre els problemes que puguis tenir amb el software instal·lat al teu servidor.",
  },

  "soporte__bullet2": {
    "es": "Resolvemos tus dudas 24/7, todo el año.",
    "en": "We answer your questions 24/7, all year.",
    "ca": "Resolem els teus dubtes 24/7, tot l'any.",
  },

  "soporte__bullet3": {
    "es": "Nada de Call Centers, atención directa.",
    "en": "No Call Centers, direct attention.",
    "ca": "Res de Call Centers, atenció directa.",
  },

  "soporte__base-de-conocimiento": {
    "es": "Base&nbsp;de&nbsp;Conocimiento",
    "en": "Knowledge&nbsp;Base",
    "ca": "Base&nbsp;de&nbsp;Coneixement",
  },

  "soporte__bullet4": {
    "es": "creada por nuestro equipo.",
    "en": "created by our team.",
    "ca": "creada pel nostre equip.",
  },

  "soporte__bullet5": {
    "es": "Incluido sin coste adicional",
    "en": "Included at no additional cost",
    "ca": "Inclòs sense cost addicional",
  },

  "soporte__soporte-section1__link": {
    "es": "¡Consúltanos!",
    "en": "Ask us anything!",
    "ca": "Consulta'ns!",
  },

  "soporte__cloud-pros": {
    "es": "Cloud Pros",
    "en": "Cloud Pros",
    "ca": "Cloud Pros",
  },


  "soporte__cloud-pros-bullet1": {
    "es": "Nos conectamos a tus servidores y resolvemos el problema por ti.",
    "en": "We connect to your servers and solve the problem for you.",
    "ca": "Ens connectem als teus servidors i resolem el problema per tu.",
  },

  "soporte__cloud-pros-bullet2": {
    "es": "Diseñamos y desplegamos soluciones complejas.",
    "en": "We design and deploy complex solutions.",
    "ca": "Dissenyem i despleguem solucions complexes.",
  },

  "soporte__cloud-pros-bullet3": {
    "es": "Todos los ingenieros de Clouding a tu disposición.",
    "en": "All Clouding engineers at your disposal.",
    "ca": "Tots els enginyers de Clouding a la teva disposició.",
  },

  "soporte__cloud-pros-bullet4": {
    "es": "Resolución rápida y eficaz 24/7.",
    "en": "Fast and efficient resolution 24/7.",
    "ca": "Resolució ràpida i eficaç 24/7.",
  },

  "soporte__cloud-pros-bullet5": {
    "es": "Tarifas asequibles.",
    "en": "Affordable rates.",
    "ca": "Tarifes assequibles.",
  },

  "soporte__cloud-pros-link": {
    "es": "¡Quiero saber más!",
    "en": "I want to find out more!",
    "ca": "Vull saber més!",
  },

  "soporte__section2-title": {
    "es": "¿Buscas un servicio de soporte avanzado?",
    "en": "Are you looking for advanced support service?",
    "ca": "Cerques un servei de suport avançat?",
  },

  "soporte__section2-subtitle": {
    "es": "Nuestros Cloud Pros resolverán por ti aquello que te preocupe y que necesite un soporte especializado",
    "en": "Our Cloud Pros will solve whatever worries you may have that need specialized support",
    "ca": "Els nostres Cloud Pros resoldran per tu allò que et preocupi i que necessiti un suport especialitzat",
  },

  "soporte__section2-button": {
    "es": "¡Contactar ahora!",
    "en": "Get in touch now!",
    "ca": "Contactar ara!",
  },

  "soporte__section3-detail": {
    "es": "Hemos creado para ti una amplia base de artículos para que puedas encontrar respuesta a todas tus consultas. Recopilamos y actualizamos toda la documentación que puede interesarte y ayudarte ante cualquier duda. ¡Compartimos contigo todo nuestro conocimiento!",
    "en": "We have created a wide base of articles so you can find answers to all your queries. We collect and update all the documentation that may interest you and help you with any questions. We share all our knowledge with you!",
    "ca": "Hem creat per a tu una àmplia base d'articles perquè puguis trobar resposta a totes les teves consultes. Recopilem i actualitzem tota la documentació que pot interessar-te i ajudar-te davant qualsevol dubte. Compartim amb tu tot el nostre coneixement!",
  },

  "soporte__section3-button": {
    "es": "¡Quiero acceder ahora!",
    "en": "I want access now!",
    "ca": "Vull accedir ara!",
  },


  // Soporte Header
  "soporte": {
    "es": "Soporte",
    "en": "Support",
    "ca": "Suport",
  },

  "cloud-pros": {
    "es": "Cloud&nbsp;Pros",
    "en": "Cloud&nbsp;Pros",
    "ca": "Cloud&nbsp;Pros",
  },

  "contacto": {
    "es": "Contacto",
    "en": "Contact",
    "ca": "Contacte",
  },


  "soporte-header__title": {
    "es": "Nuestra mayor satisfacción es dar solución<br /> a tus problemas. ¿Hablamos?",
    "en": "Our greatest satisfaction is in solving<br /> your problems. Let’s talk!",
    "ca": "La nostra major satisfacció és donar solució<br /> als teus problemes. Parlem?",
  },

  "soporte-header__description": {
    "es": "Estamos muy cerca de ti y siempre dispuestos a ayudarte.",
    "en": "We’re right here and always ready to help you.",
    "ca": "Estem molt a prop teu i sempre disposats a ajudar-te.",
  },

  "soporte-header__link": {
    "es": "Contactar con nosotros",
    "en": "Get in touch with us",
    "ca": "Contactar amb nosaltres",
  },

  // Cloud Pros
  "soporte-pro__section1-title": {
    "es": "¿Cómo te pueden ayudar nuestros Cloud Pros?",
    "en": "How can our Cloud Pros help you?",
    "ca": "Com et poden ajudar els nostres Cloud Pros?",
  },

  "soporte-pro__section1-description": {
    "es": "Si necesitas ayuda avanzada, has llegado al lugar correcto. Te explicamos cómo nuestro equipo<br /> de Cloud Pros eliminará tus problemas por ti. ¡Estos son los pasos a seguir!",
    "en": "If you need advanced help, you’ve come to the right place. We’ll explain how our Cloud Pros team will take care of your problems for you. These are the steps to follow!",
    "ca": "Si necessites ajuda avançada, has arribat al lloc correcte. T'expliquem com el nostre equip<br /> de Cloud Pros eliminarà els teus problemes per tu. Aquests són els passos a seguir!",
  },

  "soporte-pro__card1-title": {
    "es": "1. Problema",
    "en": "1. Problem",
    "ca": "1. Problema",
  },

  "soporte-pro__card1-details": {
    "es": "El primer paso es asumir que tienes un problema y explicárnoslo. ¿Qué ha pasado?",
    "en": "The first step is to assume that you have a problem. Explain it to us! What’s happened?",
    "ca": "El primer pas és assumir que tens un problema i explicar-nos-el. Què ha passat?",
  },

  "soporte-pro__card2-title": {
    "es": "2. Cálculo",
    "en": "2. Calculation",
    "ca": "2. Càlcul",
  },

  "soporte-pro__card2-details": {
    "es": "El segundo paso es que nosotros hagamos una valoración económica del problema.",
    "en": "The second step is to make an economical assessment of the problem.",
    "ca": "El segon pas és que nosaltres fem una valoració econòmica del problema.",
  },

  "soporte-pro__card3-title": {
    "es": "3. Solución",
    "en": "3. Solution",
    "ca": "3. Solució",
  },

  "soporte-pro__card3-details": {
    "es": "El tercer paso es solucionar el problema lo antes posible. ¡Sin más!",
    "en": "The third step is to solve the problem as soon as possible. That’s it!",
    "ca": "El tercer pas és solucionar el problema el més aviat possible. Sense més!",
  },

  "soporte-pro__section2-title": {
    "es": "Soporte avanzado",
    "en": "Advanced support",
    "ca": "Suport avançat",
  },

  "soporte-pro__section2-subtitle": {
    "es": "Estos son nuestros precios de soporte avanzado:",
    "en": "These are our advanced support prices:",
    "ca": "Aquests són els nostres preus de suport avançat:",
  },

  "soporte-pro__hours1-unit": {
    "es": "/30 min.",
    "en": "/30 min.",
    "ca": "/30 min.",
  },

  "soporte-pro__hours1-hours": {
    "es": "Lunes a viernes, 9h -19h",
    "en": "Monday to Friday, 9am - 7pm",
    "ca": "Dilluns a divendres, 9h -19h",
  },

  "soporte-pro__hours1-hours-mobile": {
    "es": "Lunes a viernes,<br />9h - 19h",
    "en": "Monday to Friday,<br />9am - 7pm",
    "ca": "Dilluns a divendres,<br />9h - 19h",
  },

  "soporte-pro__hours2-unit": {
    "es": "/1h",
    "en": "/1h",
    "ca": "/1h",
  },

  "soporte-pro__hours2-hours": {
    "es": "Fuera de horario",
    "en": "Out of office hours",
    "ca": "Fora d'horari",
  },

  "soporte-pro__section3-title": {
    "es": "¿Qué problemas resuelven nuestros Pros?",
    "en": "What problems do our Pros solve?",
    "ca": "Quins problemes resolen els nostres Pros?",
  },

  "soporte-pro__section3-subtitle": {
    "es": "¿Tienes algún conflicto al alojar una solución avanzada en tu VM?, ¿un problema de seguridad se te<br /> resiste y no te deja continuar? Tranquilo, ¡nuestros Pros se enfrentan a todo!",
    "en": "Do you have a conflict in hosting an advanced solution on your VM? Is there a security problem that’s causing trouble and not letting you continue? Relax, our Pros deal with everything!",
    "ca": "Tens algun conflicte en allotjar una solució avançada a la teva VM?, un problema de seguretat se't<br /> resisteix i no et deixa continuar? Tranquil, els nostres Pros s'enfronten a tot!",
  },

  "soporte-pro__section3-item1-text": {
    "es": "Instalación y<br />Configuración Apps",
    "en": "Installation and<br />App Settings",
    "ca": "Instal·lació i<br />Configuració Apps",
  },

  "soporte-pro__section3-item2-text": {
    "es": "Optimización",
    "en": "Optimization",
    "ca": "Optimització",
  },

  "soporte-pro__section3-item3-text": {
    "es": "Seguridad",
    "en": "Security",
    "ca": "Seguretat",
  },

  "soporte-pro__section3-item4-text": {
    "es": "Otros<br />problemas",
    "en": "Other<br />problems",
    "ca": "Altres<br />problemes",
  },

  "soporte-pro__section4-title": {
    "es": "¡Da el primer paso!",
    "en": "Take the first step!",
    "ca": "Dóna el primer pas!",
  },

  "soporte-pro__section4-subtitle": {
    "es": "Explica tu problema a nuestros profesionales tecnológicos",
    "en": "Explain your problem to our tech professionals",
    "ca": "Explica el teu problema als nostres professionals tecnològics",
  },

  "soporte-pro__section5-title": {
    "es": "¿Te quedan dudas?",
    "en": "Any other questions?",
    "ca": "Et queden dubtes?",
  },

  "soporte-pro__section5-detail": {
    "es": "Como sabes, en Clouding estamos siempre disponibles para cualquier consulta. Si algo no te ha<br /> quedado claro, ¡no lo dudes! Escríbenos o llámanos, nos encantará escucharte al otro lado.",
    "en": "As you know, at Clouding we are always available for any questions. If something isn’t<br />clear to you, don’t hesitate to get in touch! Write us or call us, we’d love to hear from you.",
    "ca": "Com saps, a Clouding estem sempre disponibles per a qualsevol consulta. Si alguna cosa no t'ha<br /> quedat clara, no ho dubtis! Escriu-nos o truca'ns, ens encantarà escoltar-te a l'altre costat.",
  },

  "soporte-pro__section5-detail-mobile": {
    "es": "Como sabes, en Clouding estamos siempre disponibles para cualquier consulta. Si algo no te ha quedado claro, ¡no lo dudes! Escríbenos o llámanos, nos encantará escucharte al otro lado.",
    "en": "As you know, at Clouding we are always available for any questions. If something isn’t clear to you, don’t hesitate to get in touch! Write us or call us, we’d love to hear from you.",
    "ca": "Com saps, a Clouding estem sempre disponibles per a qualsevol consulta. Si alguna cosa no t'ha quedat clara, no ho dubtis! Escriu-nos o truca'ns, ens encantarà escoltar-te a l'altre costat.",
  },

  "soporte-pro__section5-button": {
    "es": "¡Hablemos!",
    "en": "Let’s talk!",
    "ca": "Parlem!",
  },

  // SOPORTE PRO HEADER
  "soporte-pro__header-title": {
    "es": "En Clouding, ¡los problemas<br />siempre tienen solución!",
    "en": "At Clouding, problems<br />always have a solution!",
    "ca": "A Clouding, els problemes<br />sempre tenen solució!",
  },

  "soporte-pro__header-title-mobile": {
    "es": "En Clouding,<br />¡los problemas siempre<br />tienen solución!",
    "en": "At Clouding,<br />problems<br />always have a solution!",
    "ca": "A Clouding,<br /> els problemes<br />sempre tenen solució!",
  },

  "soporte-pro__header-description": {
    "es": "¡Nuestro equipo Cloud Pros resuelve cualquier situación!",
    "en": "Our Cloud Pros team fixes any situation!",
    "ca": "El nostre equip Cloud Pros resol qualsevol situació!",
  },

  "soporte-pro__header-button": {
    "es": "¡Necesito ayuda!",
    "en": "I need help!",
    "ca": "Necessito ajuda!",
  },

  "caracteristicas": {
    "es": "Características",
    "en": "Features",
    "ca": "Característiques",
  },

  "ventajas": {
    "es": "Ventajas",
    "en": "Advantages",
    "ca": "Avantatges",
  },

  "funcionalidades": {
    "es": "Funcionalidades",
    "en": "Functionalities",
    "ca": "Funcionalitats",
  },

  "infraestructura": {
    "es": "Infraestructura",
    "en": "Infrastructure",
    "ca": "Infraestructura",
  },

  "api": {
    "es": "API",
    "en": "API",
    "ca": "API",
  },

  "imagenes": {
    "es": "Imágenes",
    "en": "Images",
    "ca": "Imatges",
  },

  "nosotros": {
    "es": "Nosotros",
    "en": "About&nbsp;us",
    "ca": "Nosaltres",
  },

  "blog": {
    "es": "Blog",
    "en": "Blog",
    "ca": "Blog",
  },

  "base-de-conocimiento": {
    "es": "Base de conocimiento",
    "en": "Knowledge Base",
    "ca": "Base de coneixement",
  },

  "tarifas-y-plans": {
    "es": "Tarifas y Planes",
    "en": "Rates & Plans",
    "ca": "Tarifes i Plans",
  },

  "faq": {
    "es": "FAQ",
    "en": "FAQ",
    "ca": "FAQ",
  },

  "iniciar-sesion": {
    "es": "Iniciar Sesión",
    "en": "Login",
    "ca": "Iniciar Sessió",
  },

  // ACCEPT TERMS CONDITIONS
  "acepto1": {
    "es": "Acepto las",
    "en": "I accept the",
    "ca": "Accepto les",
  },

  "acepto1-link": {
    "es": "condiciones de uso",
    "en": "terms of use",
    "ca": "condicions d’ús",
  },

  "acepto2": {
    "es": "Acepto la información de",
    "en": "I accept the",
    "ca": "Accepto la informació de",
  },

  "acepto2-link": {
    "es": "protección de datos",
    "en": "data protection",
    "ca": "protecció de dades",
  },

  "acepto2-part2": {
    "es": "",
    "en": "information",
    "ca": "",
  },

  "acepto3": {
    "es": "Acepto la",
    "en": "I accept the",
    "ca": "Accepto la",
  },

  "acepto3-link": {
    "es": "política de privacidad",
    "en": "privacy policy",
    "ca": "política de privacitat",
  },

  // CONTACT FORM
  "name-placeholder": {
    "es": "Nombre y Apellidos",
    "en": "Full name",
    "ca": "Nom i Cognoms",
  },

  "company-placeholder": {
    "es": "Empresa",
    "en": "Company",
    "ca": "Empresa",
  },

  "email-placeholder": {
    "es": "Correo electrónico",
    "en": "Email",
    "ca": "Correu Electrònic",
  },

  "password-placeholder": {
    "es": "Contraseña",
    "en": "Password",
    "ca": "Contrasenya",
  },

  "telephone-placeholder": {
    "es": "Número de teléfono",
    "en": "Phone number",
    "ca": "Número de telèfon",
  },

  "department-placeholder": {
    "es": "Escoge departamento",
    "en": "Choose department",
    "ca": "Tria departament",
  },

  "message-placeholder": {
    "es": "Explícanos en qué podemos ayudarte",
    "en": "Tell us how we can help you",
    "ca": "Explica'ns en què podem ajudar-te",
  },

  "contact-form__button": {
    "es": "Envía tu consulta",
    "en": "Send your query",
    "ca": "Envia la teva consulta",
  },

  "contact-form-title": {
    "es": "¿Quieres contactar con nosotros?",
    "en": "Do you want to contact us?",
    "ca": "Vols contactar amb nosaltres?",
  },

  "contact-form-subtitle": {
    "es": "Estamos aquí para todo lo que necesites",
    "en": "We’re here for everything you need",
    "ca": "Som aquí per a tot el que necessitis",
  },

  "email": {
    "es": "E-mail",
    "en": "E-mail",
    "ca": "E-mail",
  },

  "email-text": {
    "es": "24 horas al día, 365 días al año",
    "en": "24 hours a day, 365 days a year",
    "ca": "24 hores al dia, 365 dies a l'any",
  },

  "telefono": {
    "es": "Teléfono",
    "en": "Phone",
    "ca": "Telèfon",
  },

  "telefono-text": {
    "es": "Lun - vie, de 9h a 14h y de 16h a 19h",
    "en": "Mon to Fri, between 9am-2pm and 4pm-7pm",
    "ca": "Dill - div, de 9h a 14h i de 16h a 19h",
  },

  "oficinas": {
    "es": "Oficinas",
    "en": "Offices",
    "ca": "Oficines",
  },

  "oficinas-text": {
    "es": "Ronda General Mitre 25, bajos<br />08017 Barcelona (España)",
    "en": "Ronda General Mitre 25, bajos<br />08017 Barcelona (Spain)",
    "ca": "Ronda General Mitre 25, baixos<br />08017 Barcelona (Espanya)",
  },

  "data-center": {
    "es": "Data Center",
    "en": "Data Center",
    "ca": "Data Center",
  },

  "data-center-text": {
    "es": "Calle Montserrat Roig, 42<br />08908 L'Hospitalet<br />de Llobregat<br />Barcelona",
    "en": "Calle Montserrat Roig, 42<br />08908 L'Hospitalet<br />de Llobregat<br />Barcelona",
    "ca": "Carrer Montserrat Roig, 42<br />08908 L'Hospitalet<br />de Llobregat<br />Barcelona",
  },

  // DROPDOWNS
  "ventajas-detail": {
    "es": "Qué nos diferencia",
    "en": "What makes us different",
    "ca": "Què ens diferencia",
  },

  "funcionalidades-detail": {
    "es": "A tu medida",
    "en": "Customized",
    "ca": "A la teva mida",
  },

  "infraestructura-detail": {
    "es": "La mejor plataforma",
    "en": "The best platform",
    "ca": "La millor plataforma",
  },

  "api-detail": {
    "es": "¡Conéctate!",
    "en": "Connect!",
    "ca": "Connecta't!",
  },

  "imagenes-detail": {
    "es": "¡Escoge la tuya!",
    "en": "Choose yours!",
    "ca": "Tria la teva!",
  },

  "mas-sobre-clouding": {
    "es": "Más sobre Clouding",
    "en": "More about Clouding",
    "ca": "Més sobre Clouding",
  },

  "conocenos": {
    "es": "¡Conócenos!",
    "en": "Get to know us!",
    "ca": "Coneix-nos!",
  },

  "contacto-detail": {
    "es": "¿Hablamos?",
    "en": "Let’s talk!",
    "ca": "Parlem?",
  },

  "blog-detail": {
    "es": "Lee las novedades",
    "en": "Read the news",
    "ca": "Llegeix les novetats",
  },

  // Soporte
  "soporte-clouding": {
    "es": "Soporte Clouding",
    "en": "Clouding Support",
    "ca": "Suport Clouding",
  },

  "soporte-clouding-detail": {
    "es": "A tu lado",
    "en": "Right here",
    "ca": "Al teu costat",
  },

  "cloud-pro": {
    "es": "Cloud Pros",
    "en": "Cloud Pros",
    "ca": "Cloud Pros",
  },

  "cloud-pro-detail": {
    "es": "A tu disposición ",
    "en": "Available for you",
    "ca": "A la teva disposició",
  },

  "base-detail": {
    "es": "¡Consulta los artículos!",
    "en": "Check out the articles!",
    "ca": "Consulta els articles!",
  },

  "soporte-cloudingio": {
    "es": "Soporte Clouding",
    "en": "Clouding Support",
    "ca": "Suport Clouding",
  },

  // copyright
  "terms": {
    "es": "Los precios recogidos en este sitio web no incluyen IVA.",
    "en": "The prices shown on this website do not include taxes.",
    "ca": "Els preus recollits en aquest lloc web no inclouen IVA.",
  },

  "terms-link": {
    "es": "Políticas y Condiciones Legales",
    "en": "Legal Policies and Conditions",
    "ca": "Polítiques i Condicions Legals",
  },

  "cloudingio-address": {
    "es": "Cloudi NextGen S.L. | B66356528 | Ronda General Mitre 25, bajos | 08017 Barcelona (España)",
    "en": "Cloudi NextGen S.L. | B66356528 | 25, local, General Mitre Round | 08017 Barcelona (Spain)",
    "ca": "Cloudi NextGen S.L. | B66356528 | Ronda General Mitre 25, baixos | 08017 Barcelona (Espanya)",
  },

  "sign-up": {
    "es": "Crea tu cuenta",
    "en": "Create your account",
    "ca": "Crea un compte",
  },

  "sign-up-free": {
    "es": "Crea tu cuenta gratis",
    "en": "Create a free account",
    "ca": "Crea el teu compte gratis",
  },

  "log-in": {
    "es": "Inicia sesión",
    "en": "Log in",
    "ca": "Inicia sessió",
  },


  "register-title": {
    "es": "Estás a un paso de dar el salto, ¡hazlo ahora!",
    "en": "You're one step away from making the jump, go for it!",
    "ca": "Estàs a un pas de fer el salt, fes-ho ara!",
  },

  "register-subtitle": {
    "es": "Regístrate gratis y sin compromiso ¡Pruébanos!",
    "en": "Sign up for free and without obligation. Give us a try!",
    "ca": "Registra't gratis i sense compromís. Prova'ns!",
  },

  "comercial": {
    "es": "Comercial",
    "en": "Commercial",
    "ca": "Comercial",
  },

  "mas-informacion": {
    "es": "Más información",
    "en": "More information",
    "ca": "Més informació",
  },

  "Este campo es obligatorio": {
    "es": "Este campo es obligatorio.",
    "en": "This field is required.",
    "ca": "Aquest camp és obligatori",
  },

  "Debe tener al menos 6 caracteres" : {
    "es": "Debe tener al menos 6 caracteres",
    "en": "Should be at least 6 characters long",
    "ca": "Ha de tenir com a mínim 6 caràcters",
  },

  "acepta las condiciones": {
    "es": "Por&nbsp;favor,&nbsp;acepta&nbsp;las&nbsp;condiciones.",
    "en": "Please&nbsp;accept&nbsp;the&nbsp;conditions.",
    "ca": "Si&nbsp;us&nbsp;plau,&nbsp;accepta&nbsp;les&nbsp;condicions.",

  },

  "acepta los avisos y comunicaciones": {
    "es": "Por&nbsp;favor,&nbsp;acepta&nbsp;la&nbsp;información&nbsp;de&nbsp;protección&nbsp;de&nbsp;datos.",
    "en": "Please,&nbsp;accept&nbsp;the&nbsp;data&nbsp;protection&nbsp;information.",
    "ca": "Si&nbsp;us&nbsp;plau,&nbsp;accepta&nbsp;la&nbsp;informació&nbsp;de&nbsp;protecció&nbsp;de&nbsp;dades.",
  },

  "acepta la politica de privacidad": {
    "es": "Por&nbsp;favor,&nbsp;acepta&nbsp;la&nbsp;política&nbsp;de&nbsp;privacidad.",
    "en": "Please,&nbsp;accept&nbsp;the&nbsp;privacy&nbsp;policy.",
    "ca": "Si&nbsp;us&nbsp;plau,&nbsp;accepta,&nbsp;accepta&nbsp;la&nbsp;política&nbsp;de&nbsp;privacitat.",
  },

  "title1-404": {
    "es": "¡No te preocupes!",
    "en": "Don’t worry!",
    "ca": "No et preocupis!",
  },

  "title2-404": {
    "es": "Es solo un error 404...",
    "en": "It’s just a 404 error...",
    "ca": "Només és un error 404",
  },

  "description-404": {
    "es": "Lo sentimos, la página que estás buscando no está disponible en estos momentos.",
    "en": "We’re sorry, the page you’re looking for isn’t available right now.",
    "ca": "Ho sentim, la pàgina que estàs buscant no està disponible en aquests moments",
  },

  "link-404": {
    "es": "¡Vuelve a nuestra Home!",
    "en": "Go back to our Homepage!",
    "ca": "Torna a la nostra Home!",
  },

  "home-title": {
    "es": "VPS Cloud: Tu Servidor Virtual en España - ¡Pruébanos!",
    "en": "VPS Cloud: Your Virtual Server in Europe - Try it out now!",
    "ca": "VPS Cloud: El teu Servidor Virtual a Barcelona - Prova'ns!",
  },

  "home-meta-description": {
    "es": "Hosting de Servidores Cloud VPS en la nube, Data Center en España, discos SSD, con Linux o Windows y facturados por horas. ¡Pruébalos!",
    "en": "Cloud VPS Server Hosting service, Data Center in Europe, SSD disks, Linux and Windows available and billed by the hour. Try them out!",
    "ca": "Hosting de Servidors Cloud VPS en el núvol, Data Center a Barcelona, discos SSD, amb Linux o Windows i facturats per hores. Prova'ls!",
  },

  "caracteristicas-title": {
    "es": "Las mejores características para el mejor Servidor Cloud VPS",
    "en": "The best features for the best Cloud VPS Server",
    "ca": "Les millors característiques per al millor Servidor Cloud VPS",
  },

  "caracteristicas-meta-description": {
    "es": "Los Cloud VPS funcionan sobre KVM y procesadores de gama Data Center. Incluyen la mejor conectividad y Anti-DDOS sin coste adicional. Backups y Snapshots disponibles",
    "en": "Cloud VPS Servers run on KVMs and Data Center processors. Best connectivity and Anti-DOS included at no additional cost. Spapshots and Backups available",
    "ca": "Els Cloud VPS funcionen sobre KVM i processadors de gama data center. Inclouen la millor connectivitat i Anti-DDOS sense cost addicional. Backups i Snapshots disponibles",
  },

  "nosotros-title": {
    "es": "Conoce más sobre Clouding",
    "en": "Learn more about Clouding",
    "ca": "Coneix més sobre Clouding",
  },

  "nosotros-meta-description": {
    "es": "Cercanos, creativos, con más de 15 años de experiencia en el sector, ¡y con mucha pasión por nuestro trabajo!",
    "en": "Personal, creative, with more than 15 years of experience in the sector, and with a lot of passion for our work!",
    "ca": "Propers, creatius, amb més de 15 anys d'experiència en el sector, i amb molta passió per la nostra feina!",
  },

  "soporte-title": {
    "es": "El mejor soporte para tu Servidor Cloud VPS",
    "en": "The best support for your VPS Cloud Server",
    "ca": "El millor suport per al teu Servidor Cloud VPS",
  },

  "soporte-meta-description": {
    "es": "Nuestra mayor satisfacción es dar solución a tus problemas. Estamos muy cerca de ti y siempre dispuestos a ayudarte.",
    "en": "Our greatest satisfaction is in solving your problems. We’re right here and always ready to help you.",
    "ca": "La nostra major satisfacció és donar solució als teus problemes. Estem molt a prop teu i sempre disposats a ajudar-te.",
  },

  // Links
  "index.html": {
    "es": "",
    "en": "",
    "ca": "",
  },

  "caracteristicas.html": {
    "es": "caracteristicas",
    "en": "features",
    "ca": "caracteristiques",
  },

  "nosotros.html": {
    "es": "nosotros",
    "en": "about-us",
    "ca": "nosaltres",
  },

  "soporte.html": {
    "es": "soporte",
    "en": "support",
    "ca": "suport",
  },

  "404.html": {
    "es": "404",
    "en": "404",
    "ca": "404",
  },

  "homeLinkPath": {
    "es": "",
    "en": "en/",
    "ca": "ca/",
  },

  "cif and address": {
    "es": "B66356528&nbsp;| Ronda&nbsp;General&nbsp;Mitre&nbsp;25,&nbsp;bajos&nbsp;| 08017&nbsp;Barcelona&nbsp;(España)",
    "en": "B66356528&nbsp;| 25,&nbsp;local,&nbsp;General&nbsp;Mitre&nbsp;Round&nbsp;| 08017&nbsp;Barcelona&nbsp;(Spain)",
    "ca": "B66356528&nbsp;| Ronda&nbsp;General&nbsp;Mitre&nbsp;25,&nbsp;baixos&nbsp;| 08017&nbsp;Barcelona&nbsp;(Espanya)",
  },
  
  "nosotros-contact-subject": {
    "es": "Contacto Web",
    "en": "Contacte Web",
    "ca": "Web Contact Request",
  },

  "soporte-contact-subject": {
    "es": "Soporte Web",
    "en": "Suport Web",
    "ca": "Web Support Request",
  },

  "cloud-pros-contact-subject": {
    "es": "Contacto Cloud Pros",
    "en": "Contacte Cloud Pros",
    "ca": "Cloud Pros Request",
  },

  "contact-form-success": {
    "es": "¡Gracias por tu mensaje! ¡Contactaremos contigo antes de lo que esperas!",
    "en": "Thanks for your message! We'll contact you sooner than you expect!",
    "ca": "Gràcies pel teu missatge! Ens posarem en contacte amb tu abans del que t'esperes!",
  },

  // For URL purposes, don't change between here
  //  |
  //  |
  //  V

  "archivado-id": {
    "es": "archivado",
    "en": "archived",
    "ca": "arxivat",
  },

  "redimensionado-id": {
    "es": "redimensionado",
    "en": "resized",
    "ca": "redimensionat",
  },

  "snapshots-id": {
    "es": "snapshots",
    "en": "snapshots",
    "ca": "snapshots",
  },

  "backups-id": {
    "es": "backups",
    "en": "backups",
    "ca": "backups",
  },

  "red-privada-id": {
    "es": "red-privada",
    "en": "private-network",
    "ca": "xarxa-privada",
  },

  "alta-disponibilidad-id": {
    "es": "alta-disponibilidad",
    "en": "high-availability",
    "ca": "alta-disponibilitat",
  },

  "factoracion-por-horas-id": {
    "es": "facturacion-por-horas",
    "en": "billed-by-the-hour",
    "ca": "facturacio-per-hores",
  },

  "contacto-id": {
    "es": "contacto",
    "en": "contact",
    "ca": "contacte",
  },


  "nosotros-contacto-id": {
    "es": "nosotros-contacto",
    "en": "about-us-contact",
    "ca": "nosaltres-contacte",
  },

  "ventajas-section": {
    "es": "ventajas",
    "en": "advantages",
    "ca": "avantatges",
  },

  "infraestructura-section": {
    "es": "infraestructura",
    "en": "infrastructure",
    "ca": "infraestructura",
  },

  "funcionalidades-section": {
    "es": "funcionalidades",
    "en": "functionalities",
    "ca": "funcionalitats",
  },

  "api-section": {
    "es": "api",
    "en": "api",
    "ca": "api",
  },

  "imagenes-section": {
    "es": "imagenes",
    "en": "images",
    "ca": "imatges",
  },

  "cloud-pros-section": {
    "es": "cloud-pros",
    "en": "cloud-pros",
    "ca": "cloud-pros",
  },

  //  ^
  //  |
  //  |
  // For URL purposes, don't change between here


  "proximamente": {
    "es": "¡próximamente!",
    "en": "coming Soon!",
    "ca": "properament!",
  },
  
  "clouding-speedtest": {
    "es": "Test de velocidad de Clouding.io",
    "en": "Clouding.io speedtest",
    "ca": "Test de velocitat de Clouding.io",
  },

};


