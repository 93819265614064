import $ from 'jquery';


export function scrollToContactForm() {

  switch (window.location.hash) {
    case "#contacto":
    case "#contact":
    case "#contacte":
      const contactSectionIds = [
        '#nosotros-contacto',
        '#about-us-contact',
        '#nosaltres-contacte',
        '#cloud-pros_contacto',
        '#cloud-pros_contact',
        '#cloud-pros_contacte',
        '#contacto',
        '#contact',
        '#contacte',
      ];

      const top = contactSectionIds.reduce((prev, curr) => {
        let top = 0;
        try {
          top = $(curr).offset().top;
        }
        catch (err) { }

        return top + prev;
      }, 0);

      scrollTo(top);
      break;

    default:
      break;
  }

}

export function scrollTo(top) {
  var page = $("html, body");

  // Disable the floating header when scrolling programatically
  $('.floating-header').addClass('disabled');
  function callback() {
    // Wait an extra bit, JQuery is still animating the display: none;
    setTimeout(function () {
      $('.floating-header').removeClass('disabled');
    }, 1000);

    page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
  }

  page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function () {
    console.log('stopped scroll animation');
    page.stop();
    page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
  });

  $('html, body').animate({
    scrollTop: top
  }, 1000, callback);
}
