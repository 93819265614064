// // not used images
// const dimensions =
//   [
//     {
//       mobile: {
//         src: '/img/testimonials/Almeritek.png',
//         height: 33,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/Almeritek@2x.png',
//         height: 59,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/FacilTac.png',
//         height: 93,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/FacilTac@2x.png',
//         height: 168,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/GUDA.png',
//         height: 46,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/GUDA@2x.png',
//         height: 80,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/Invagapp.png',
//         height: 41,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/Invagapp@2x.png',
//         height: 78,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/Jorgedavidperez.png',
//         height: 41,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/Jorgedavidperez@2x.png',
//         height: 78,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/PreBachiller.png',
//         height: 28,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/PreBachiller@2x.png',
//         height: 54,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/Tengafe.png',
//         height: 58,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/Tengafe@2x.png',
//         height: 107,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/Vegalia.png',
//         height: 48,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/Vegalia@2x.png',
//         height: 104,
//         width: 250,
//       },
//     },
//     {
//       mobile: {
//         src: '/img/testimonials/Venexma.png',
//         height: 71,
//         width: 135,
//       },
//       desktop: {
//         src: '/img/testimonials/Venexma@2x.png',
//         height: 131,
//         width: 250,
//       },
//     },
//   ];


const positions = {

  "autonomo": {
    "es": "Autónomo",
    "en": "Freelance",
    "ca": "Autònom",
  },

  "co-fundador": {
    "es": "Co-Fundador",
    "en": "Co-Founder",
    "ca": "Co-Fundador",
  },

  "consultor-de-marketing-online": {
    "es": "Consultor de Marketing Online",
    "en": "Marketing Online Consultant",
    "ca": "Consultor de Màrqueting Online",
  },

  "coordinador-de-marketing-online": {
    "es": "Coordinador de Marketing Online",
    "en": "Marketing Online Coordinator",
    "ca": "Coordinador de Màrqueting Online",
  },

  "departamento-de-sistemas-de-euskodata": {
    "es": "Departamento de Sistemas de Euskodata",
    "en": "Euskodata Systems Department",
    "ca": "Departament de Sistemes de Euskodata",
  },

  "desarrollador-de-aplicaciones-web-y-multiplataforma": {
    "es": "Desarrollador de Aplicaciones Web y Multiplataforma",
    "en": "Multiplatform and Web Apps Developer",
    "ca": "Desenvolupador d'Aplicacions Web i Multiplataforma",
  },

  "director": {
    "es": "Director",
    "en": "Manager",
    "ca": "Director",
  },

  "director-comercial": {
    "es": "Director Comercial",
    "en": "Business Manager",
    "ca": "Director Comercial",
  },

  "director-creativo": {
    "es": "Director Creativo",
    "en": "Creative Director",
    "ca": "Director Creatiu",
  },

  "director-de-operaciones": {
    "es": "Director de Operaciones",
    "en": "Operations Manager",
    "ca": "Director d'Operacions",
  },

  "director-de-proyectos": {
    "es": "Director de Proyectos ",
    "en": "Project Manager",
    "ca": "Director de Projectes",
  },

  "ceo": {
    "es": "CEO",
    "en": "CEO",
    "ca": "CEO",
  },

  "chief-executive-officer": {
    "es": "Director Ejecutivo",
    "en": "Chief Executive Officer",
    "ca": "Director Executiu",
  },

  "director-ejecutivo-y-fundador": {
    "es": "Director Ejecutivo y Fundador",
    "en": "Chief Executive Officer and Founder",
    "ca": "Director Executiu i Fundador",
  },

  "director-ejecutivo-y-responsable-de-sistemas": {
    "es": "Director Ejecutivo y Responsable de Sistemas",
    "en": "Chief Executive Officer and Systems Manager",
    "ca": "Director Executiu i Responsable de Sistemes",
  },

  "chief-technical-officer": {
    "es": "Director Técnico",
    "en": "Chief Technical Officer",
    "ca": "Director Tècnic",
  },

  "director-tecnico": {
    "es": "Director Técnico",
    "en": "Technical Manager",
    "ca": "Director Tècnic",
  },

  "director-y-fundador": {
    "es": "Director y Fundador",
    "en": "Manager and Founder",
    "ca": "Director i Fundador",
  },

  "fundador": {
    "es": "Fundador",
    "en": "Founder",
    "ca": "Fundador",
  },

  "fundadora": {
    "es": "Fundadora",
    "en": "Founder",
    "ca": "Fundadora",
  },

  "gerente": {
    "es": "Gerente",
    "en": "Manager",
    "ca": "Gerent",
  },

  "gerente-y-desarrollador-web": {
    "es": "Gerente y Desarrollador Web",
    "en": "Manager and Web Developer",
    "ca": "Gerent i Desenvolupador Web",
  },

  "ingeniero-de-software-senior": {
    "es": "Ingeniero de Software Senior",
    "en": "Senior Software Engineer ",
    "ca": "Enginyer de Software Sènior",
  },

  "ingeniero-informatico-y-desarrollador-web": {
    "es": "Ingeniero Informático y Desarrollador Web",
    "en": "IT Engineer and Web Developer",
    "ca": "Enginyer Informàtic i Desenvolupador Web",
  },

  "jefe-de-desarrollos-y-tecnologia": {
    "es": "Jefe de Desarrollos y Tecnología",
    "en": "Technology and Development Manager",
    "ca": "Cap de Desenvolupaments i Tecnologia",
  },

  "jefe-de-equipo-de-desarrollo-web": {
    "es": "Jefe de Equipo de Desarrollo Web",
    "en": "Web Development Team Leader",
    "ca": "Cap d'Equip de Desenvolupament Web",
  },

  "chief-operations-officer": {
    "es": "Jefe de Operaciones",
    "en": "Chief Operations Officer",
    "ca": "Cap d'Operacions",
  },

  "travel-hacker": {
    "es": "Travel Hacker",
    "en": "Travel Hacker",
    "ca": "Travel Hacker",
  },

  "programador-autonomo": {
    "es": "Programador Autónomo",
    "en": "Freelance Programmer",
    "ca": "Programador Autònom",
  },

  "programador-web-y-app": {
    "es": "Programador Web y App",
    "en": "App and Web Programmer",
    "ca": "Programador Web i App",
  },

  "propietario": {
    "es": "Propietario",
    "en": "Owner",
    "ca": "Propietari",
  },

  "responsable-de-desarrollo-y-gestion": {
    "es": "Responsable de Desarrollo y Gestión",
    "en": "Development and Management Director",
    "ca": "Responsable de Desenvolupament i Gestió",
  },

  "responsable-de-informatica": {
    "es": "Responsable de Informática",
    "en": "IT Manager",
    "ca": "Responsable d'Informàtica",
  },

  "responsable-de-marketing-y-comunicacion": {
    "es": "Responsable de Marketing y Comunicación",
    "en": "Marketing and Communications Manager",
    "ca": "Responsable de Comunicació i Màrqueting",
  },

  "responsable-de-producto": {
    "es": "Responsable de Producto",
    "en": "Product Manager",
    "ca": "Responsable de Producte",
  },

  "responsable-tecnico": {
    "es": "Responsable Técnico",
    "en": "Technical Manager",
    "ca": "Responsable Tècnic",
  },

  "responsable-tic": {
    "es": "Responsable TIC",
    "en": "TIC Manager",
    "ca": "Responsable TIC",
  },

  "socio-administrador": {
    "es": "Socio Administrador",
    "en": "Administrator Partner ",
    "ca": "Soci Administrador",
  },

  "socio-director": {
    "es": "Socio Director",
    "en": "Owner Manager",
    "ca": "Soci Director",
  },

  "socio-fundador": {
    "es": "Socio Fundador",
    "en": "Founder Partner",
    "ca": "Soci Fundador",
  },

  "socio-y-director": {
    "es": "Socio y Director",
    "en": "Partner and Manager",
    "ca": "Soci i Director",
  },
}

export default [
  {
    href: "https://www.imprentaonline24.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/ImprentaOnline24.png',
        height: 103,
        width: 70,
      },
      desktop: {
        src: '/img/testimonials/ImprentaOnline24@2x.png',
        height: 147,
        width: 100,
      },
    },
    quote: {
      "es": "Ningún otro proveedor ofrece el nivel de satisfacción, estabilidad y rapidez de Clouding",
      "en": "Ningún otro proveedor ofrece el nivel de satisfacción, estabilidad y rapidez de Clouding",
      "ca": "Ningún otro proveedor ofrece el nivel de satisfacción, estabilidad y rapidez de Clouding",
    },
    name: "Fran Egea",
    position: positions['ceo'],
  },
  {
    href: "https://dmainformatica.net/",
    img:
    {
      mobile: {
        src: '/img/testimonials/DMAinformatica.png',
        height: 36,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/DMAinformatica@2x.png',
        height: 62,
        width: 250,
      },
    },
    quote: {
      "es": "El equilibrio perfecto entre servicio, asistencia y precio",
      "en": "The perfect balance between service, assistance and price",
      "ca": "L'equilibri perfecte entre servei, assistència i preu",
    },
    name: "Jaume Dalmau",
    position: positions['gerente'],
  },
  {
    href: "https://www.desvio21.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Desvio21.png',
        height: 58,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Desvio21@2x.png',
        height: 93,
        width: 250,
      },
    },
    quote: {
      "es": "Servidores superestables, panel de control intuitivo y servicio técnico siempre atento y eficiente",
      "en": "Incredibly stable servers, intuitive control panel and a technical service that’s always attentive and effective",
      "ca": "Servidors superestables, panell de control intuïtiu i servei tècnic sempre atent i eficient",
    },
    name: "Maria Garcia Abril",
    position: positions['chief-technical-officer'],
  },
  {
    href: "https://www.ciberweb.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Ciberweb.png',
        height: 92,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Ciberweb@2x.png',
        height: 167,
        width: 250,
      },
    },
    quote: {
      "es": "Gran facilidad de gestionar máquinas y automatizar copias. No es necesario un máster para gestionar mis servidores",
      "en": "Managing machines and automating copies is so easy. I don't need a master's degree to manage my servers!",
      "ca": "Gran facilitat de gestionar màquines i automatitzar còpies. No cal un màster per gestionar els meus servidors",
    },
    name: "Alfonso Javier Gil",
    position: positions['programador-web-y-app'],
  },
  {
    href: "https://clubnomada.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/clubnomada.png',
        height: 92,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/clubnomada@2x.png',
        height: 162,
        width: 250,
      },
    },
    quote: {
      "es": "¡Exactamente lo que buscaba! Sencillo e intuitivo: pago por horas y sin permanencias. Lo recomendaría una y mil veces",
      "en": "Exactly what I was looking for! Simple and intuitive: you pay by the hour with no fixed terms. I’d recommend it a thousand times",
      "ca": "Exactament el que buscava! Senzill i intuïtiu: pagament per hores i sense permanències. El recomanaria una i mil vegades",
    },
    name: "Javier Díaz",
    position: positions['travel-hacker'],
  },
  {
    href: "https://marketingdigitalinmobiliario.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Mkting-Digital-Mobilario.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Mkting-Digital-Mobilario@2x.png',
        height: 86,
        width: 250,
      },
    },
    quote: {
      "es": "¡Un servicio excelente! Servidores potentes con tiempos de carga asombrosos",
      "en": "Excellent service! Powerful servers with amazing load times",
      "ca": "Un servei excel·lent! Servidors potents amb temps de càrrega sorprenents",
    },
    name: "Jorge Hernández ",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://www.eleplus.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/Eleplus.png',
        height: 30,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Eleplus@2x.png',
        height: 53,
        width: 250,
      },
    },
    quote: {
      "es": "Servidores virtuales potentes y fiables. Gestión ágil y sencilla. Backups automáticos. Totalmente recomendable",
      "en": "Powerful and reliable virtual servers. Agile and simple management. Automatic backups. Fully recommended",
      "ca": "Servidors virtuals potents i fiables. Gestió àgil i senzilla. Backups automàtics. Totalment recomanable",
    },
    name: "Francisco Aieta Álvaro",
    position: positions['gerente'],
  },
  {
    href: "https://anibal.pro",
    img:
    {
      mobile: {
        src: '/img/testimonials/AnibalPro.png',
        height: 89,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/AnibalPro@2x.png',
        height: 160,
        width: 250,
      },
    },
    quote: {
      "es": "Rapidez en el despliegue de servidores, fiabilidad y buen precio",
      "en": "Fast server deployment, reliability and a good price",
      "ca": "Rapidesa en el desplegament de servidors, fiabilitat i bon preu",
    },
    name: "Anibal Díaz",
    position: positions['programador-autonomo'],
  },
  {
    href: "https://www.afiche.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Afiche.png',
        height: 34,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Afiche@2x.png',
        height: 56,
        width: 250,
      },
    },
    quote: {
      "es": "Profesionalidad y excelente atención. Una gran ayuda para el crecimiento de nuestra empresa",
      "en": "Professionalism and excellent service. A great help for the growth of our company",
      "ca": "Professionalitat i excel·lent atenció. Una gran ajuda per al creixement de la nostra empresa",
    },
    name: "Jose Angel de Mur",
    position: positions['gerente'],
  },
  {
    href: "https://trocauto.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Trocauto.png',
        height: 39,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Trocauto@2x.png',
        height: 72,
        width: 250,
      },
    },
    quote: {
      "es": "Siempre están ahí para atenderte con rapidez y eficacia. Clouding es sinónimo de excelencia",
      "en": "They are always there to help you quickly and efficiently. Clouding stands for excellence",
      "ca": "Sempre hi són per atendre't amb rapidesa i eficàcia. Clouding és sinònim d'excel·lència",
    },
    name: "Oscar Rojas",
    position: positions['gerente'],
  },
  {
    href: "https://ibaica.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Ibaica.png',
        height: 46,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Ibaica@2x.png',
        height: 80,
        width: 250,
      },
    },
    quote: {
      "es": "Hemos optimizado costes en servidores, configurándolos según nuestras necesidades",
      "en": "We’ve optimized costs on servers, setting them up according to our needs",
      "ca": "Hem optimitzat costos en servidors, configurant segons les nostres necessitats",
    },
    name: "Manel Nebot",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://gerardcanovas.cat/",
    img:
    {
      mobile: {
        src: '/img/testimonials/GerardCanovas.png',
        height: 67,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/GerardCanovas@2x.png',
        height: 119,
        width: 250,
      },
    },
    quote: {
      "es": "Sin dudarlo, mi partner tecnológico preferido para mis proyectos",
      "en": "Without a doubt, my preferred technology partner for all my projects",
      "ca": "Sense dubtar-ho, el meu partner tecnològic preferit per als meus projectes",
    },
    name: "Gerard Canovas",
    position: positions['autonomo'],
  },
  {
    href: "https://backgrid.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/BackGRID.png',
        height: 48,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/BackGRID@2x.png',
        height: 76,
        width: 250,
      },
    },
    quote: {
      "es": "Muy contentos con el servicio: fácil de usar, económico y con buen soporte. ¡Seguid así!",
      "en": "We’re very happy with the Clouding service: easy to use, economical and good support. Keep it up!",
      "ca": "Molt contents amb el servei: fàcil d'usar, econòmic i amb bon suport. Seguiu així!",
    },
    name: "Alberto Erice",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://todoseguridad.org/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Todoseguridad.png',
        height: 34,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Todoseguridad@2x.png',
        height: 65,
        width: 250,
      },
    },
    quote: {
      "es": "¡Poder hacer una copia de seguridad o restaurarla en solo unos segundos no tiene precio!",
      "en": "Being able to make a backup or restore it in just a few seconds is priceless!",
      "ca": "Poder fer una còpia de seguretat o restaurar-la en només uns segons no té preu!",
    },
    name: "Arturo Egileta",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://ancoradual.com",
    img:
    {
      mobile: {
        src: '/img/testimonials/Ancora-dual.png',
        height: 102,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Ancora-dual@2x.png',
        height: 178,
        width: 250,
      },
    },
    quote: {
      "es": "¡Migrar todos nuestros servidores a Clouding ha sido nuestra mejor decisión!",
      "en": "Migrating all of our servers to Clouding has been the best decision we’ve made!",
      "ca": "Migrar tots els nostres servidors a Clouding ha estat la nostra millor decisió!",
    },
    name: "Sergio García",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://qualtic.cat/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Qualtic.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Qualtic@2x.png',
        height: 88,
        width: 250,
      },
    },
    quote: {
      "es": "Por rendimiento, flexibilidad y un excelente equipo de soporte, Clouding es nuestro proveedor VPS de referencia",
      "en": "For performance, flexibility and an excellent support team, Clouding has become our top VPS provider",
      "ca": "Per rendiment, flexibilitat i un excel·lent equip de suport, Clouding és el nostre proveïdor VPS de referència",
    },
    name: "Marc Granados",
    position: positions['director-ejecutivo-y-responsable-de-sistemas'],
  },
  {
    href: "https://ateneasystems.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Ateneassystems.png',
        height: 64,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Ateneassystems@2x.png',
        height: 112,
        width: 250,
      },
    },
    quote: {
      "es": "El servicio técnico es uno de los mejores que he conocido",
      "en": "The technical service is one of the best I’ve come across",
      "ca": "El servei tècnic és un dels millors que he conegut",
    },
    name: "Paco Cubel",
    position: positions['autonomo'],
  },
  {
    href: "https://timestopping.com",
    img:
    {
      mobile: {
        src: '/img/testimonials/Timestopping.png',
        height: 34,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Timestopping@2x.png',
        height: 65,
        width: 250,
      },
    },
    quote: {
      "es": "Clouding funciona tan bien, que me olvido de que lo estoy usando. ¡Y me encanta su precio!",
      "en": "Clouding works so well that I forget I’m even using it, and I love the price!",
      "ca": "Clouding funciona tan bé, que m'oblido que ho estic fent servir. I m'encanta el seu preu!",
    },
    name: "Martín Alejandro Carmona Selva",
    position: positions['autonomo'],
  },
  {
    href: "https://www.latevaweb.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Latevaweb.png',
        height: 36,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Latevaweb@2x.png',
        height: 68,
        width: 250,
      },
    },
    quote: {
      "es": "¡Disponer de IP propia y no sufrir las inconveniencias de un servidor compartido es genial!",
      "en": "Having our own IP and not going through the inconvenience of a shared server is great!",
      "ca": "Disposar d'IP pròpia i no patir les inconveniències d'un servidor compartit és genial!",
    },
    name: "Bruno Díaz ",
    position: positions['coordinador-de-marketing-online'],
  },
  {
    href: "https://bedazzling.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Bedazzling.png',
        height: 84,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Bedazzling@2x.png',
        height: 150,
        width: 250,
      },
    },
    quote: {
      "es": "Su departamento técnico es 100% recomendable. ¡Muy eficaces!",
      "en": "Their technical department is 100% recommendable. Very effective!",
      "ca": "El seu departament tècnic és 100% recomanable. Molt eficaços!",
    },
    name: "Jordi Martínez",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://www.okodia.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Okodia.png',
        height: 48,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Okodia@2x.png',
        height: 90,
        width: 250,
      },
    },
    quote: {
      "es": "Nos encanta Clouding: plataforma estable y gran atención al cliente",
      "en": "We love working with Clouding: stable platform and great customer service",
      "ca": "Ens encanta Clouding: plataforma estable i gran atenció a client",
    },
    name: "Luis Rodríguez",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://www.euskodata.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Eusko-Data.png',
        height: 32,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Eusko-Data@2x.png',
        height: 58,
        width: 250,
      },
    },
    quote: {
      "es": "Alto rendimiento y calidad de sus máquinas virtuales. Y con un servicio rápido, resolutivo y cercano",
      "en": "High performance and quality of their virtual machines, and with a fast, decisive and personal service",
      "ca": "Alt rendiment i qualitat de les seves màquines virtuals. I amb un servei ràpid, resolutiu i pròxim",
    },
    name: "",
    position: positions['departamento-de-sistemas-de-euskodata'],
  },
  {
    href: "https://www.d-retail.com",
    img:
    {
      mobile: {
        src: '/img/testimonials/dretail.png',
        height: 84,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/dretail@2x.png',
        height: 153,
        width: 250,
      },
    },
    quote: {
      "es": "¡Nos permite poner en marcha aplicaciones web en cuestión de minutos!",
      "en": "It lets us launch web applications in just a few minutes!",
      "ca": "Ens permet posar en marxa aplicacions web en qüestió de minuts!",
    },
    name: "Ramon Teixidó",
    position: positions['socio-y-director'],
  },
  {
    href: "https://diphone-web-1527837907287.firebaseapp.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Diphone.png',
        height: 101,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Diphone@2x.png',
        height: 185,
        width: 250,
      },
    },
    quote: {
      "es": "Ágiles, con buen precio y con servicio técnico muy por encima de la media",
      "en": "Agile, with a good price and technical service well above average!",
      "ca": "Àgils, amb bon preu i amb servei tècnic molt per sobre de la mitjana",
    },
    name: "Albert Rovira",
    position: positions['autonomo'],
  },
  {
    href: "https://tuvoleibol.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Tuvoleibol.png',
        height: 87,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Tuvoleibol@2x.png',
        height: 164,
        width: 250,
      },
    },
    quote: {
      "es": "He probado muchos hostings y VPS y, sin duda alguna, Clouding da el mejor servicio",
      "en": "I have tried many hosts and VPS and, without a doubt, Clouding gives the best service",
      "ca": "He provat molts hostings i VPS i, sens dubte, Clouding dóna el millor servei",
    },
    name: "Juan Manuel Guisado Igualada",
    position: positions['autonomo'],
  },
  {
    href: "https://www.safecreative.org/",
    img:
    {
      mobile: {
        src: '/img/testimonials/safestamper.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/safestamper@2x.png',
        height: 86,
        width: 250,
      },
    },
    quote: {
      "es": "Plataforma fácil de usar y muy estable",
      "en": "A platform that’s easy to use and very stable",
      "ca": "Plataforma fàcil d'usar i molt estable",
    },
    name: "Mario Pena",
    position: positions['chief-operations-officer'],
  },
  {
    href: "https://www.agrobio.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Agrobio.png',
        height: 67,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Agrobio@2x.png',
        height: 108,
        width: 250,
      },
    },
    quote: {
      "es": "Desde que trabajamos con Clouding la gestión de los VPS es una nueva experiencia",
      "en": "Since we’ve been working with Clouding, the management of the VPS is a new experience!",
      "ca": "Des que treballem amb Clouding la gestió dels VPS és una nova experiència",
    },
    name: "Angel Fernández",
    position: positions['responsable-de-informatica'],
  },
  {
    href: "https://algoepico.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Algoepico.png',
        height: 107,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Algoepico@2x.png',
        height: 194,
        width: 250,
      },
    },
    quote: {
      "es": "El mejor proveedor con el que he trabajado. Rápido y estable, con servidores en España y soporte técnico impecable",
      "en": "The best cloud hosting provider I've worked with. Fast and stable, with servers in Spain and impeccable technical support",
      "ca": "El millor proveïdor amb el qual he treballat. Ràpid i estable, amb servidors a Espanya i suport tècnic impecable",
    },
    name: "Javier Busquets",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://fmeuropa.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/FlashTelecom.png',
        height: 83,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/FlashTelecom@2x.png',
        height: 146,
        width: 250,
      },
    },
    quote: {
      "es": "El sistema es muy robusto e intuitivo de configurar",
      "en": "The system is very robust and intuitive to set up",
      "ca": "El sistema és molt robust i intuïtiu de configurar",
    },
    name: "Ricardo Monge",
    position: positions['responsable-de-producto'],
  },
  {
    href: "https://cocheshots.club/",
    img:
    {
      mobile: {
        src: '/img/testimonials/cocheshots.jpg',
        height: 74,
        width: 300,
      },
      desktop: {
        src: '/img/testimonials/cocheshots.jpg',
        height: 74,
        width: 300,
      },
    },
    quote: {
      "es": "¡Maravillosa empresa! Soporte 24 horas y rápido. Y precios que no encontrarás en ninguna otra parte",
      "en": "Wonderful company! Fast, 24-hour support and prices you won’t find anywhere else",
      "ca": "Meravellosa empresa! Suport 24 hores i ràpid. I preus que no trobaràs en cap altra part",
    },
    name: "Elvis Novas",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://ctbarbanza.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/CentroTecnoloxicodo.png',
        height: 93,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/CentroTecnoloxicodo@2x.png',
        height: 169,
        width: 250,
      },
    },
    quote: {
      "es": "Hemos descubierto una alternativa a plataformas de VPS más conocidas, con la que estamos muy contentos",
      "en": "We’ve discovered an alternative to better-known VPS platforms, and we’re very happy",
      "ca": "Hem descobert una alternativa a plataformes de VPS més conegudes, amb la qual estem molt contents",
    },
    name: "Jorge Parada Fernandez",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://tacuatec.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Tacuatec.png',
        height: 48,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Tacuatec@2x.png',
        height: 86,
        width: 250,
      },
    },
    quote: {
      "es": "Encontré la solución que necesitaba: excelente rendimiento y precios competitivos",
      "en": "I found the solution I needed: excellent performance and competitive prices",
      "ca": "He trobat la solució que necessitava: excel·lent rendiment i preus competitius",
    },
    name: "Esteban Sanjurjo",
    position: positions['propietario'],
  },
  {
    href: "https://www.atecocolli.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Atecocolli.png',
        height: 100,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Atecocolli@2x.png',
        height: 187,
        width: 250,
      },
    },
    quote: {
      "es": "¡Su servicio es perfecto!",
      "en": "Their service is perfect!",
      "ca": "El seu servei és perfecte!",
    },
    name: "Ángel Eduardo Juárez Maldonado",
    position: positions['director-y-fundador'],
  },
  {
    href: "https://www.dev-dreams.eu",
    img:
    {
      mobile: {
        src: '/img/testimonials/DevDreams.png',
        height: 96,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/DevDreams@2x.png',
        height: 178,
        width: 250,
      },
    },
    quote: {
      "es": "Clouding es la forma más rápida y sencilla de crear el servidor que realmente necesitas para comenzar tu negocio",
      "en": "Clouding is the fastest and simplest way to create the server you really need to start your business",
      "ca": "Clouding és la forma més ràpida i senzilla de crear el servidor que realment necessites per començar el teu negoci",
    },
    name: "Jorge Miguel Oliveira Gonçalves",
    position: positions['fundador'],
  },
  {
    href: "https://eduqueo.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Eduqueo.png',
        height: 55,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Eduqueo@2x.png',
        height: 99,
        width: 250,
      },
    },
    quote: {
      "es": "Nos permite redimensionar nuestros servidores una vez están en uso: es la flexibilidad que necesitamos a un coste excelente",
      "en": "It lets us create servers as soon as they’re in use, with the flexibility we need at an excellent cost",
      "ca": "Ens permet redimensionar els nostres servidors un cop estan en ús: és la flexibilitat que necessitem a un cost excel·lent",
    },
    name: "Marcelo Bor",
    position: positions['director-de-operaciones'],
  },
  {
    href: "https://www.waliky.net/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Waliky.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Waliky@2x.png',
        height: 83,
        width: 250,
      },
    },
    quote: {
      "es": "¡Con Clouding, cero problemas! Nos gustan los precios pero el soporte técnico nos enamoró",
      "en": "With Clouding we have zero problems! We like the prices but fell in love with the technical support!",
      "ca": "Amb Clouding, zero problemes! Ens agraden els preus però el suport tècnic ens va enamorar",
    },
    name: "Albert K. Camargo Barreda",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://www.cezetazeta.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Cezerazeta.png',
        height: 28,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Cezerazeta@2x.png',
        height: 49,
        width: 250,
      },
    },
    quote: {
      "es": "¡Si eres desarrollador te encantará! Crear y modificar servidores instantáneamente, precio por horas, auto-backups y una latencia/rendimiento increíbles",
      "en": "If you’re a developer, you’ll love it! Create and modify servers instantly, price per hour, auto-backups and incredible latency and performance",
      "ca": "Si ets desenvolupador t'encantarà! Crear i modificar servidors instantàniament, preu per hores, acte-backups i una latència / rendiment increïbles",
    },
    name: "Rafael Calero",
    position: positions['fundador'],
  },
  {
    href: "https://www.impactotecnologico.net",
    img:
    {
      mobile: {
        src: '/img/testimonials/Impactotecnologico.png',
        height: 46,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Impactotecnologico@2x.png',
        height: 80,
        width: 250,
      },
    },
    quote: {
      "es": "Excelente servicio, tanto en rendimiento como en estabilidad, y a muy buen precio",
      "en": "Excellent service, both in performance and stability, and at a very good price",
      "ca": "Excel·lent servei, tant en rendiment com en estabilitat, i a molt bon preu",
    },
    name: "José Julián Ariza Valderrama",
    position: positions['director-ejecutivo-y-fundador'],
  },
  {
    href: "https://www.mejorconweb.com/es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Mejorconweb.png',
        height: 36,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Mejorconweb@2x.png',
        height: 67,
        width: 250,
      },
    },
    quote: {
      "es": "Muy satisfechos con el servicio y la atención al cliente: el soporte responde rápida y eficazmente",
      "en": "We are very satisfied with the service and customer support, which responds quickly and efficiently",
      "ca": "Molt satisfets amb el servei i l'atenció a client: el suport respon ràpida i eficaçment",
    },
    name: "Conrado Fonts",
    position: positions['director-creativo'],
  },
  {
    href: "https://it.doezos.com",
    img:
    {
      mobile: {
        src: '/img/testimonials/Doezos.png',
        height: 98,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Doezos@2x.png',
        height: 174,
        width: 250,
      },
    },
    quote: {
      "es": "El servicio es inmejorable y los servidores vuelan",
      "en": "The service is unbeatable and the servers fly",
      "ca": "El servei és immillorable i els servidors volen",
    },
    name: "Carlos Torrado",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://artmondo.fr",
    img:
    {
      mobile: {
        src: '/img/testimonials/Artmondo.png',
        height: 67,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Artmondo@2x.png',
        height: 121,
        width: 250,
      },
    },
    quote: {
      "es": "El soporte técnico de Clouding siempre es estelar. Nada es comparable en el mercado",
      "en": "Clouding technical support is always stellar. Nothing is comparable in the market",
      "ca": "El suport tècnic de Clouding sempre és estel·lar. Res és comparable al mercat",
    },
    name: "Francois Gombault",
    position: positions['co-fundador'],
  },
  {
    href: "https://www.tercalor.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Termaservicios.png',
        height: 71,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Termaservicios@2x.png',
        height: 125,
        width: 250,
      },
    },
    quote: {
      "es": "Muy contentos con el servicio, sin cortes y con una buena atención personalizada, un 10",
      "en": "We’re very happy with the service, without cuts and with a good personal attention, 10 out of 10",
      "ca": "Molt contents amb el servei, sense talls i amb una bona atenció personalitzada, un 10",
    },
    name: "Carlos Aguado Torrejón",
    position: positions['gerente'],
  },
  {
    href: "https://www.franciscougalde.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Franciscougalde.png',
        height: 30,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Franciscougalde@2x.png',
        height: 56,
        width: 250,
      },
    },
    quote: {
      "es": "Clouding sencillamente combina todo lo necesario para hacer crecer tu negocio",
      "en": "Clouding simply combines everything you need to grow your business",
      "ca": "Clouding senzillament combina tot el necessari per fer créixer el teu negoci",
    },
    name: "Francisco Ugalde",
    position: positions['ingeniero-de-software-senior'],
  },
  {
    href: "https://sumiloc.com/es",
    img:
    {
      mobile: {
        src: '/img/testimonials/Sumiloc.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Sumiloc@2x.png',
        height: 86,
        width: 250,
      },
    },
    quote: {
      "es": "El servicio es excelente. Gran soporte técnico y buen panel de control, estoy muy satisfecho como cliente",
      "en": "The service is excellent. Great technical support and good control panel. As a customer I’m very satisfied",
      "ca": "El servei és excel·lent. Gran suport tècnic i bon panell  de control, estic molt satisfet com a client",
    },
    name: "Eduardo Millán",
    position: positions['director-de-proyectos'],
  },
  {
    href: "https://www.marketing.barcelona",
    img:
    {
      mobile: {
        src: '/img/testimonials/MKB-MarketingBarcelona.png',
        height: 93,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/MKB-MarketingBarcelona@2x.png',
        height: 168,
        width: 250,
      },
    },
    quote: {
      "es": "De los mejores servidores que existen: no lo digo yo, lo dicen los tests. Atención y soporte exquisitos: un 10",
      "en": "Of the best servers that exist, I don't need to say anything, the tests say it all. Exquisite attention and support: 10/10",
      "ca": "Dels millors servidors que existeixen: no ho dic jo, ho diuen els tests. Atenció i suport exquisits: un 10",
    },
    name: "Xavi Gómez",
    position: positions['autonomo'],
  },
  {
    href: "https://www.dainso.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Dainso.png',
        height: 109,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Dainso@2x.png',
        height: 191,
        width: 250,
      },
    },
    quote: {
      "es": "Tenemos la flexibilidad que necesitamos más un servicio excelente, de proximidad y a un precio muy competitivo",
      "en": "We’ve got the flexibility we need as well as excellent service, local, and at a very competitive price",
      "ca": "Tenim la flexibilitat que necessitem més un servei excel·lent, de proximitat i a un preu molt competitiu",
    },
    name: "Antoni Obrado",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://www.yntegra2.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/Yntegra2.png',
        height: 70,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Yntegra2@2x.png',
        height: 125,
        width: 249,
      },
    },
    quote: {
      "es": "Estamos encantados, nos tratan como si fueramos su mejor cliente",
      "en": "We are delighted, they treat us as if we were their best customer",
      "ca": "Estem encantats, ens tracten com si fóssim el seu millor client",
    },
    name: "Jordi Pelegrin",
    position: positions['director-comercial'],
  },
  {
    href: "https://www.adarsus.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/MetaClean.png',
        height: 91,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/MetaClean@2x.png',
        height: 168,
        width: 250,
      },
    },
    quote: {
      "es": "Recomendamos Clouding por su facilidad de uso, autonomía, estabilidad y atención al cliente personalizada",
      "en": "We recommend Clouding for its ease of use, autonomy, stability and personal customer service",
      "ca": "Recomanem Clouding per la seva facilitat d'ús, autonomia, estabilitat i atenció a client personalitzada",
    },
    name: "Guzmán Rejón",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://elconsentimiento.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Elconsentimiento.png',
        height: 41,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Elconsentimiento@2x.png',
        height: 78,
        width: 250,
      },
    },
    quote: {
      "es": "Excelente servicio y soporte inmediato en español",
      "en": "Excellent service and immediate support in Spanish",
      "ca": "Excel·lent servei i suport immediat en espanyol",
    },
    name: "Javier Diego Martín",
    position: positions['jefe-de-desarrollos-y-tecnologia'],
  },
  {
    href: "https://monvpn.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/monVPN.png',
        height: 30,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/monVPN@2x.png',
        height: 58,
        width: 250,
      },
    },
    quote: {
      "es": "¿VPS confiable y rápido en España?¡Clouding es de primera categoría!",
      "en": "Reliable and fast VPS in Spain? Clouding is top-notch!",
      "ca": "¿VPS fiable i ràpid a Espanya? 'Clouding és de primera categoria!",
    },
    name: "",
    position: { es: "", en: "", ca: "" }
  },
  {
    href: "https://lolapirindola.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/LolaPirindola.png',
        height: 87,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/LolaPirindola@2x.png',
        height: 162,
        width: 250,
      },
    },
    quote: {
      "es": "Nos encanta diseñar nuestros propios servidores en la nube dependiendo de las necesidades del momento",
      "en": "We love designing our own servers in the cloud depending on the needs of the moment",
      "ca": "Ens encanta dissenyar els nostres propis servidors en el núvol depenent de les necessitats de el moment",
    },
    name: "Eva Anta",
    position: positions['responsable-de-marketing-y-comunicacion'],
  },
  {
    href: "https://www.informatica7.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Informatica7.png',
        height: 25,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Informatica7@2x.png',
        height: 47,
        width: 250,
      },
    },
    quote: {
      "es": "Muy contentos con el rendimiento y la calidad de Clouding. Los servidores son muy rápidos y el servicio técnico es perfecto",
      "en": "We’re very happy with the performance and quality of the Clouding. The servers are very fast and the technical service is perfect",
      "ca": "Molt contents amb el rendiment i la qualitat de Clouding. Els servidors són molt ràpids i el servei tècnic és perfecte",
    },
    name: "Antonio Moreno",
    position: positions['gerente'],
  },
  {
    href: "https://quares.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/Quares.png',
        height: 48,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Quares@2x.png',
        height: 88,
        width: 250,
      },
    },
    quote: {
      "es": "Me gusta la tranquilidad de tener en un hosting en España, seguro, estable y económico",
      "en": "I like the peace of mind in having a secure, stable and economical host in Spain",
      "ca": "M'agrada la tranquil·litat de tenir en un hosting a Espanya, segur, estable i econòmic",
    },
    name: "Marino García",
    position: positions['director-tecnico'],
  },
  {
    href: "https://www.lobetec.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/Lobetec.png',
        height: 36,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Lobetec@2x.png',
        height: 68,
        width: 250,
      },
    },
    quote: {
      "es": "La solución perfecta para nuestros desarrollos en Oracle APEX, ofreciendo a nuestros clientes modificar disco SSD, RAM o CPU rápidamente",
      "en": "The perfect solution for our developments in Oracle APEX, allowing our customers to modify SSD, RAM or CPU disk quickly",
      "ca": "La solució perfecta per als nostres desenvolupaments en Oracle APEX, oferint als nostres clients modificar disc SSD, RAM o CPU ràpidament",
    },
    name: "Marino García",
    position: positions['fundador'],
  },
  {
    href: "https://envemos.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Envemos.png',
        height: 90,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Envemos@2x.png',
        height: 168,
        width: 250,
      },
    },
    quote: {
      "es": "¡Respondieron todas mis consultas por email en tan solo 1 minuto!  Además, me llamaron para asegurarse de que no tenía dudas",
      "en": "They answered all my questions by email in just one minute! They also called me up to make sure I didn’t have any questions",
      "ca": "Van respondre totes les meves consultes per email en tan sols 1 minut! A més, em van trucar per assegurar-se que no tenia dubtes",
    },
    name: "Enrique Vergara Molina",
    position: positions['desarrollador-de-aplicaciones-web-y-multiplataforma'],
  },
  {
    href: "https://runtrailapp.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/Runtrail.png',
        height: 83,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Runtrail@2x.png',
        height: 153,
        width: 250,
      },
    },
    quote: {
      "es": "Excelente plataforma que combina agilidad de uso, potencia y libertad a la hora de auto-gestionarla",
      "en": "Excellent platform that combines agility of use, power and freedom when it comes to self-management",
      "ca": "Excel·lent plataforma que combina agilitat d'ús, potència i llibertat a l'hora d'auto-gestionar-la",
    },
    name: "José Antonio López López ",
    position: positions['responsable-de-desarrollo-y-gestion'],
  },
  {
    href: "https://www.juanonlab.com",
    img:
    {
      mobile: {
        src: '/img/testimonials/JuanonLab.png',
        height: 31,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/JuanonLab@2x.png',
        height: 60,
        width: 250,
      },
    },
    quote: {
      "es": "Rendimiento y respuesta del servidor excelentes",
      "en": "Excellent server performance and response",
      "ca": "Rendiment i resposta de l'servidor excel·lents",
    },
    name: "Juan Pardo Palazón",
    position: positions['responsable-tecnico'],
  },
  {
    href: "https://www.vaplove.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Vaplove.png',
        height: 92,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Vaplove@2x.png',
        height: 164,
        width: 250,
      },
    },
    quote: {
      "es": "Máxima satisfacción, tanto con el soporte técnico como con el funcionamiento de nuestros servidores",
      "en": "Maximum satisfaction, both with technical support and with the operation of our servers",
      "ca": "Màxima satisfacció, tant amb el suport tècnic com amb el funcionament dels nostres servidors",
    },
    name: "Jose Maldonado",
    position: positions['propietario'],
  },
  {
    href: "https://noticiasgibraltar.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/noticiasgibraltar.png',
        height: 19,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/noticiasgibraltar@2x.png',
        height: 36,
        width: 250,
      },
    },
    quote: {
      "es": "¡Excelente servicio! Adaptable, claro, rápido y cercano",
      "en": "Excellent service! Adaptable, clear, fast and personal",
      "ca": "Excel·lent servei! Adaptable, clar, ràpid i proper",
    },
    name: "Juan Carlos Jiménez Laz",
    position: positions['director'],
  },
  {
    href: "https://chatbotchocolate.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Chatbot-Chocolate.png',
        height: 103,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Chatbot-Chocolate@2x.png',
        height: 179,
        width: 250,
      },
    },
    quote: {
      "es": "Una solución que se ajusta a lo que necesitamos: a buen precio y con una atención al cliente de lo más rápida",
      "en": "A solution that fits what we need, at a good price and with the fastest customer service",
      "ca": "Una solució que s'ajusta al que necessitem: a bon preu i amb una atenció a el client d'allò més ràpida",
    },
    name: "Angel Hernandez",
    position: positions['socio-director'],
  },
  {
    href: "https://www.102novadoc.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/novadoc.png',
        height: 42,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/novadoc@2x.png',
        height: 69,
        width: 250,
      },
    },
    quote: {
      "es": "Ni una sola incidencia desde que estamos en Clouding",
      "en": "Not a single issue since we’ve been with Clouding",
      "ca": "Ni una sola incidència des que estem a Clouding",
    },
    name: "José J. Cegarra Roca",
    position: positions['socio-administrador'],
  },
  {
    href: "https://zenithsoluciones.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Zenithsoluciones.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Zenithsoluciones@2x.png',
        height: 89,
        width: 249,
      },
    },
    quote: {
      "es": "¡Todo un placer estar en Clouding! Impecable atención al cliente y gran eficacia y amabilidad de su equipo técnico",
      "en": "A pleasure to be with Clouding! Impeccable customer service and great efficiency and friendliness of their technical team",
      "ca": "Tot un plaer estar a Clouding! Impecable atenció a client i gran eficàcia i amabilitat del seu equip tècnic",
    },
    name: "Elizabeth Parra ",
    position: positions['fundadora'],
  },
  {
    href: "https://www.vuelosa1euro.es",
    img:
    {
      mobile: {
        src: '/img/testimonials/Vuelosa1euro.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Vuelosa1euro@2x.png',
        height: 83,
        width: 250,
      },
    },
    quote: {
      "es": "Me encanta Clouding por su flexibilidad y porque se adapta a las necesidades que mi negocio necesita en cada momento",
      "en": "I love Clouding because of its flexibility and because it adapts quickly to the needs that my business requires at all times",
      "ca": "M'encanta Clouding per la seva flexibilitat i perquè s'adapta a les necessitats que el meu negoci necessita en cada moment",
    },
    name: "Noelia Berenguer",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://tandemsl.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/TandemHSE.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/TandemHSE@2x.png',
        height: 86,
        width: 250,
      },
    },
    quote: {
      "es": "Muy profesionales, nunca hemos tenido ningún problema y el soporte técnico es muy bueno",
      "en": "Very professional, we’ve never had any problems and the technical support is very good",
      "ca": "Molt professionals, mai hem tingut cap problema i el suport tècnic és molt bo",
    },
    name: "Omar González",
    position: positions['responsable-tic'],
  },
  {
    href: "https://www.vegagestion.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Vegagestion.png',
        height: 27,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Vegagestion@2x.png',
        height: 52,
        width: 250,
      },
    },
    quote: {
      "es": "La facilidad de crear servidores virtuales, la gestión ágil y el precio han sido la clave para pasarme a Clouding",
      "en": "The ease of creating virtual servers, agile management and price have been the key to switching to Clouding",
      "ca": "La facilitat de crear servidors virtuals, la gestió àgil i el preu han estat la clau per passar-me a Clouding",
    },
    name: "David Galdo",
    position: positions['socio-fundador'],
  },
  {
    href: "https://diferenciart.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Diferenciart.png',
        height: 32,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Diferenciart@2x.png',
        height: 56,
        width: 250,
      },
    },
    quote: {
      "es": "Clouding me ha sorprendido desde el primer momento. ¡El panel es muy intuitivo y los servidores van genial!",
      "en": "Clouding has surprised me from the start: The panel is very intuitive and the servers work great",
      "ca": "Clouding m'ha sorprès des del primer moment. ¡El panell és molt intuïtiu i els servidors van genial!",
    },
    name: "David Cegarra",
    position: positions['consultor-de-marketing-online'],
  },
  {
    href: "https://puntcom.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Puntcom.png',
        height: 60,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Puntcom@2x.png',
        height: 111,
        width: 250,
      },
    },
    quote: {
      "es": "No soy un experto en virtualizaciones, pero Clouding me lo ha puesto fácil",
      "en": "I’m not an expert in virtualizations, but Clouding has made it easy for me",
      "ca": "No sóc un expert en virtualitzacions, però Clouding m'ho ha posat fàcil",
    },
    name: "Joaquín García Gandía",
    position: positions['propietario'],
  },
  {
    href: "https://domoticaencasa.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Domoticaencasa.png',
        height: 46,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Domoticaencasa@2x.png',
        height: 84,
        width: 250,
      },
    },
    quote: {
      "es": "De todas las plataformas que he probado, Clouding es la más sencilla y nunca me ha dado problemas, ¡muy recomendable!",
      "en": "Of all the platforms I've tried, Clouding is the easiest and has never given me problems, highly recommended!",
      "ca": "De totes les plataformes que he provat, Clouding és la més senzilla i mai m'ha donat problemes, molt recomanable!",
    },
    name: "Eduardo Ruiz",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://winamic.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Winamic.png',
        height: 45,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Winamic@2x.png',
        height: 83,
        width: 249,
      },
    },
    quote: {
      "es": "¡Clouding fue un gran descubrimiento! Nunca he tenido incidencias y el soporte técnico es bueno y cercano",
      "en": "Clouding was a great discovery! I have never had any problems and the technical support is good and personal",
      "ca": "Clouding va ser un gran descobriment! Mai he tingut incidències i el suport tècnic és bo i proper",
    },
    name: "Rubén",
    position: positions['gerente-y-desarrollador-web'],
  },
  {
    href: "https://www.goltratec.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Goltratec.png',
        height: 50,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Goltratec@2x.png',
        height: 91,
        width: 250,
      },
    },
    quote: {
      "es": "Soporte técnico de 10, y a diferencia de la mayoría de empresas, en español",
      "en": "Technical support 10 out of 10, and unlike most companies, in Spanish",
      "ca": "Suport tècnic de 10, i a diferència de la majoria d'empreses, en espanyol",
    },
    name: "Jesús Yepes",
    position: positions['jefe-de-equipo-de-desarrollo-web'],
  },
  {
    href: "https://treeala.com/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Treeala.png',
        height: 51,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Treeala@2x.png',
        height: 89,
        width: 250,
      },
    },
    quote: {
      "es": "La mejor opción calidad-precio que existe en el mercado. ¡Gracias por vuestro compromiso por mejorar cada vez más!",
      "en": "The best value for money in the market. Thank you for your commitment to improve more and more!",
      "ca": "La millor opció qualitat-preu que hi ha al mercat. Gràcies pel vostre compromís per millorar cada vegada més!",
    },
    name: "Pablo Rafael González Méndez",
    position: positions['chief-executive-officer'],
  },
  {
    href: "https://www.bivium.es/",
    img:
    {
      mobile: {
        src: '/img/testimonials/Biviumestudio.png',
        height: 44,
        width: 135,
      },
      desktop: {
        src: '/img/testimonials/Biviumestudio@2x.png',
        height: 77,
        width: 250,
      },
    },
    quote: {
      "es": "El mayor valor: la rapidez para restablecer el servicio, siempre con un soporte eficaz. Gracias, equipo Clouding!",
      "en": "The greatest value: the speed of restoring the service, always with the most effective support. Thanks, Clouding team!",
      "ca": "El major valor: la rapidesa per restablir el servei, sempre amb un suport eficaç. Gràcies, equip Clouding!",
    },
    name: "Jose Javier Aguado Martín",
    position: positions['chief-executive-officer'],
  }
]
