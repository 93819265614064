import $ from 'jquery';

$(function addFloatingHeader() {
  var heightBuffer = 0;
  var $lastScrollTop = 0;
  $(window).scroll(function () {
    var $height = $(this).scrollTop();
    if ($height === 0) {
      $('.floating-header').removeClass('active');
    }
    // If the header is disabled, we want to remove the active class.
    else if ($('.floating-header').hasClass('disabled')) {
      $('.floating-header').removeClass('active');
    }
    else if ($height < $lastScrollTop) {
      heightBuffer++;
      if (heightBuffer > 20) {
        $('.floating-header').addClass('active');
      }
    }
    else {
      heightBuffer = 0;
      $('.floating-header').removeClass('active');
    }

    $lastScrollTop = $height;
  });
});